import "../../../style/comment/comment.view.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as React from "react";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { toast } from "react-toastify";
import { setContactAction } from "../../../services/contacts.service";
import {
  createPriceNegotiation,
  setLeadAction,
} from "../../../services/leads.service";
import constants from "../../../constants";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { setProjectAction } from "../../../services/projects.service";
import { convert } from "../../../utils/formatTime";
import { companyActionMarkAsDone } from "../../../services/companies.service";
import { useMatchCollectionName } from "../../../utils/filterObject";
import { useState, useMemo, useRef } from "react";
import styled from "@emotion/styled";
import {
  createEstimation,
  getAllEstimates,
  updateEstimationbyId,
  getAllEstimation,
  getEstimationByActionId,
  latestEstimation,
  createLeadProposal,
  estimationDetailsByType,
  getLeadProposalPDFUrlByActionId,
} from "../../../services/estimate.service";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { AuthContext } from "../../../context/auth.context";
import EstimationMenu from "./EstimationMenu";
import { proposalTemplates } from "../../../services/proposal.service";
import AddPricenegociation from "../../Lead/AddPricenegociation";
import CloseIcon from "@mui/icons-material/Close";
import "./EstimateTemplateEditor.css";
import { cleanHtml } from "../../../utils/convertHTMLtoNormalText";
import JoditEditor from "jodit-react";
import { Editor } from "@tinymce/tinymce-react";
import parse from "html-react-parser";
import { debounce } from "lodash";
import { useEffect } from "react";
import CostCalculatorDialog from "../../Lead/CostCalculatorDialog";
import {
  storeCostCalcutedData,
  updateCostCalculatedData,
} from "../../../services/calculatorSetting.service";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
export const generateContent = (headings) => {
  console.log(headings, "headdd");
  return headings
    .map(
      (heading, index) =>
        // `<div><b style="font-weight: bold;">${index + 1}. ${
        // `<div><b>${index + 1}. ${heading?.outlineName}</b></div></br> </br>`
        `<p>${index + 1}. ${heading.outlineName}</p> </br> </br>`
    )
    .join("");
};

function Action({
  toDo,
  doer,
  dueDate,
  dueTime,
  isDone,
  doneOn,
  doneBy,
  actionType,
  actionId,
  id,
  leadId,
  projectId,
  setLoadedActions,
  setPageCount,
  FetchActions,
  from,
  assignedBy,
  contactName,
  leadSummary,
  projectTitle,
  doerId,
  techOwner,
  contactInfoObj,
  meetLink,
  getAllDoers,
  isProjectEditPage,
  companyId,
  fromLeadPage,
  companyName,
  isEstimationCreated,
  isProposalCreated,
  isCostCalculationCreated = false,
  loadedActions,
  storeTemplate,
  setStoreTemplate,
  setCopiedValue,
  copiedValue,
  template,
  setTemplate,
  fromCreateOne,
  setFromCreateOne,
  setIsPriceNegotiation,
  storeCurrentTemplate,
  setStoreCurrentTemplate,
  selectedValue,
  setSelectedValue,
  lead,
  content,
  setContent,
  previousContent,
  setPreviousContent,
  setUpdateLeadCalled,
}) {
  console.log(isEstimationCreated, "created....");
  const editor = useRef(null);
  // const [content, setContent] = useState("");
  // const [previousContent, setPreviousContent] = useState("");
  const [logs, setLogs] = useState([]);
  const { currentUser } = React.useContext(AuthContext);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const elements = ["Intro", "Subject", "Email"];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [editorValues, setEditorValues] = useState([
    {
      description: "",
      element: "",
    },
  ]);
  const [Expanded, setExpanded] = useState(0);
  const [showEstimate, setShowEstimate] = useState(false);
  const [estimateActionDialouge, setEstimateActionDialouge] = useState(false);

  const [createTemplate, setCreateTemplate] = useState([]);
  const [actionIdEstimate, setActionIdEstimate] = useState(null);
  const [actionIdEstimatePayload, setActionIdEstimatePayload] = useState([]);
  const [checkUpdateData, setCheckUpdateData] = useState([]);
  const [isTemplateDialogeOpen, setIsTemplateDialogeOpen] = useState(false);
  const [isTemplateEditDialoge, setIsTemplateEditDialoge] = useState(false);
  const [isTemplateConfirmationOpen, setTemplateConfirmationOpen] =
    useState(false);
  const [openAutoComplete, setOpenAutocomplete] = React.useState(false);
  const [isopenApiLoaded, setIsopenApiLoaded] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [allHeadingEffortDetails, setAllHeadingEffortDetails] = useState([]);
  const [allHeadingCostDetails, setAllHeadingCostDetails] = useState([]);
  const [allEstimation, setAllEstimation] = useState([]);
  const [loader, setLoader] = useState(true);

  // const [selectedValue, setSelectedValue] = React.useState(null);
  // const [selectedValue, setSelectedValue] = React.useState({});
  const loading =
    openAutoComplete &&
    (allHeadingEffortDetails.length === 0 ||
      allHeadingCostDetails.length === 0);
  console.log(options, loading, "options...");
  const [estimateData, setEstimateData] = useState({
    contactId: "",
    leadId: "",
    actionId: "",
    estimationHeading: "",
    outlines: [],
  });
  const [allHeadingDetails, setAllHeadingDetails] = useState([]);

  const [outlines, setOutlines] = useState([]);

  const dynamicActionType = useMatchCollectionName(actionType?.toLowerCase());
  // proposal state start

  const [openProposalDialouge, setOpenProposalDialouge] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  let initialProposalData = [
    {
      section: "Introduction",
      sectionName: "",
      dataTemplate: "",
      outlineArray: [],
      skip: false,
    },
    {
      section: "Executive Summary",
      sectionName: "",
      dataTemplate: "",
      outlineArray: [],
      skip: false,
    },
    {
      section: "Project Details",
      sectionName: "",
      dataTemplate: "",
      outlineArray: [], //html
      htmlTemplates: "",
      skip: false,
    },
    {
      section: "Cost Details",
      sectionName: "",
      dataTemplate: "",
      outlineArray: [],
      htmlTemplates: "",
      skip: false,
    },
  ];
  const initialPriceData = {
    cost: "",
    currency: "",
    schedule: "",
  };
  const [priceData, setPriceData] = useState(initialPriceData);
  const [proposalData, setProposalData] = useState(initialProposalData);
  const [proposaldataTemplate, setProposaldataTemplate] = useState([]);
  const [effortEstimate, setEffortEstimate] = useState([]);
  const [costEstimate, setCostEstimate] = useState([]);
  const [isProsalOpen, setIsProposalOpen] = useState(false);
  const [proposalUrl, setProposalUrl] = useState("");

  // proposal state end

  //cost calculator start
  const [isCostCalculationDialogOpen, setIsCostCalculationDialogOpen] =
    useState(false);
  const [costCalculationDialogMode, setCostCalculationDialogMode] =
    useState("Create"); // or "View"
  const [costCalculationData, setCostCalculationData] = useState([]);
  //cost calculator end
  const config = useMemo(
    () => ({
      uploader: {
        insertImageAsBase64URI: true, // Use base64 encoding to simplify the example
      },
      //   toolbarSticky: false,
      //   buttons: [
      //     "source",
      //     "|",
      //     "bold",
      //     "italic",
      //     "underline",
      //     "strikethrough",
      //     "eraser",
      //     "superscript",
      //     "subscript",
      //     "|",
      //     "ul",
      //     "ol",
      //     "|",
      //     "outdent",
      //     "indent",
      //     "|",
      //     "font",
      //     "fontsize",
      //     "brush",
      //     "paragraph",
      //     "|",
      //     "image",
      //     "file",
      //     "video",
      //     "table",
      //     "link",
      //     "|",
      //     "align",
      //     "undo",
      //     "redo",
      //     "|",
      //     "hr",
      //     "copyformat",
      //     "fullsize",
      //     "selectall",
      //     "print",
      //     "|",
      //     "symbol",
      //     "preview",
      //     "find",
      //     "cut",
      //     "copy",
      //     "paste",
      //     "paste-as-html",
      //     "paste-from-word",
      //     "selectall",
      //     "lock",
      //   ],
      //   extraButtons: [
      //     [
      //       "source",
      //       "|",
      //       "bold",
      //       "italic",
      //       "underline",
      //       "strikethrough",
      //       "|",
      //       "eraser",
      //       "superscript",
      //       "subscript",
      //     ],
      //     [
      //       "ul",
      //       "ol",
      //       "|",
      //       "outdent",
      //       "indent",
      //       "|",
      //       "font",
      //       "fontsize",
      //       "brush",
      //       "paragraph",
      //     ],
      //     [
      //       "image",
      //       "file",
      //       "video",
      //       "table",
      //       "link",
      //       "|",
      //       "align",
      //       "undo",
      //       "redo",
      //     ],
      //     [
      //       "hr",
      //       "copyformat",
      //       "fullsize",
      //       "selectall",
      //       "print",
      //       "|",
      //       "symbol",
      //       "preview",
      //       "find",
      //       "cut",
      //       "copy",
      //       "paste",
      //       "paste-as-html",
      //       "paste-from-word",
      //       "selectall",
      //       "lock",
      //     ],
      //   ],
      readonly: false,
    }),
    []
  );

  const navigate = useNavigate();
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  let authUser = JSON.parse(localStorage.getItem("AUTH_USER"));
  const isDoerOrAdminOrLeadSalesOwner =
    fromLeadPage &&
    (authUser.name === doer.name ||
      currentUser.role === "ADMIN" ||
      (currentUser._id === lead.salesOwner && isDone));

  const isDoerOrAdmin =
    fromLeadPage &&
    (authUser.name === doer.name || currentUser.role === "ADMIN");

  function dateChange(date) {
    const dateTimeInSeconds = new Date(date).getTime();
    const offSet = new Date().getTimezoneOffset();
    const offSetInSeconds = offSet * 60 * 1000;
    const newDateInSeconds = dateTimeInSeconds - offSetInSeconds;
    const dateString = [
      padTo2Digits(new Date(newDateInSeconds).getUTCDate()),
      padTo2Digits(new Date(newDateInSeconds).getUTCMonth() + 1),
      new Date(newDateInSeconds).getUTCFullYear(),
    ].join("/");
    if (
      new Date(new Date().setHours(0, 0, 0, 0)).getHours() ===
        new Date(newDateInSeconds).getUTCHours() &&
      new Date(new Date().setHours(0, 0, 0, 0)).getMinutes() ===
        new Date(newDateInSeconds).getUTCMinutes()
    ) {
      return dateString;
    } else {
      return dateString + "  at  " + placeHoursAndMinutes(newDateInSeconds);
    }
  }

  function placeHoursAndMinutes(newDateInSeconds) {
    const hours = new Date(newDateInSeconds).getUTCHours();
    const stamp = hours > 12 ? "PM" : "AM";

    const newHours =
      hours > 12
        ? hours - 12 >= 10
          ? hours - 12
          : `0${hours - 12}`
        : hours >= 10
        ? hours
        : `0${hours}`;
    const minutes = new Date(newDateInSeconds).getUTCMinutes();
    const newMinutes = minutes >= 10 ? minutes : `0${minutes}`;
    const time = `${newHours}:${newMinutes} ${stamp}`;
    return time;
  }

  const markAsDone = async () => {
    let response;
    let authUser = JSON.parse(localStorage.getItem("AUTH_USER"));

    try {
      const date = {
        doneOn: new Date(),
      };
      if (actionType === constants.LEADS) {
        response = await setLeadAction(id, leadId, actionId, date);
        setUpdateLeadCalled(false);
      }
      if (actionType === constants.PROJECTS) {
        response = await setProjectAction(projectId, actionId, date);
      }
      if (actionType === constants.CONTACTS) {
        response = await setContactAction(id, actionId, date);
      }
      if (actionType === constants.COMPANIES) {
        response = await companyActionMarkAsDone(companyId, actionId, date);
      }
      if (response && response.data.status) {
        if (fromLeadPage) {
          getAllDoers();
        }
        if (from !== constants.DASHBOARD) {
          setLoadedActions([]);
          setPageCount(1);
        }
        FetchActions();
        toast("Action updated successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAccordionChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : 0);
  };

  const style =
    from === constants.DASHBOARD
      ? { fontWeight: "bold", paddingLeft: "20px" }
      : { fontWeight: "bold" };
  const handleClick = (id, leadId, actionId) => {
    if (actionType === constants.CONTACTS) {
      navigate(`/contacts/${id}`, {
        state: {
          actionId,
          from,
        },
      });
    } else if (actionType === constants.LEADS) {
      navigate(`/Leads/${leadId}/${id}`, {
        state: {
          actionId,
          from,
        },
      });
    } else if (actionType === constants.COMPANIES) {
      navigate(`/companies/view/${companyId}`, {
        state: {
          actionId,
          from,
        },
      });
    } else {
      navigate(`/projects/${projectId}`, {
        state: {
          actionId,
          from,
        },
      });
    }
  };
  const handleChange = (event) => {
    setTemplate(event.target.value);
  };
  const handleEstimateActionDialouge = (e) => {
    setEstimateActionDialouge((prev) => !prev);
  };
  const handleEstimateEditAction = (e) => {
    setIsTemplateEditDialoge((prev) => !prev);
    setTimeout(() => {
      setLoader((prev) => (prev = false));
    }, 5000);
  };

  console.log("createTemplate", storeTemplate, selectedValue);
  const handleClickMenu = async (
    event,
    actionId,
    isFromCopyButton,
    actionType
  ) => {
    if (isFromCopyButton) {
      const copiedEstimation = await getEstimationActionById(actionId);
      console.log(copiedEstimation, "copyyy");

      if (copiedEstimation) {
        // const matchingHeading = allHeadingDetails.find(
        //   (elem) => copiedEstimation.estimationHeading === elem.headingName
        // );
        if (storeCurrentTemplate) {
          const updatedOutlines = storeCurrentTemplate?.outlines?.map(
            (currentItem) => {
              const previousItem = copiedEstimation.outlines.find(
                (item) => item.outlineName === currentItem.outlineName
              );
              if (previousItem) {
                return {
                  ...currentItem,
                  description: previousItem.description,
                };
              } else {
                return currentItem;
              }
            }
          );
          console.log(updatedOutlines, "copyyy inside");
          setStoreTemplate(() => ({
            headingName: storeCurrentTemplate.headingName,
            estimateType: storeCurrentTemplate.estimateType,
            outlines: updatedOutlines,
            actionType,
          }));
          setContent(copiedEstimation.estimateDescription);
          toast.success("Estimation copied sucessfully!");
          setCopiedValue((prev) => true);
          return;
        } else {
          toast.warn("Please select the template first before you copy!");
          return;
        }
      } else {
        toast.warn("No estimation details to copy!");
        return;
      }
    }
    console.log("actionIdeee", actionId);
    getEstimationActionById(actionId);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchHeadingDetails = async () => {
    // setIsLoaderEnable(true);
    try {
      const response = await getAllEstimates();
      if (response.data.status) {
        // setIsLoaderEnable(false);

        response.data.data.forEach((item) => {
          item.outlines.forEach((outline) => {
            if (!outline.hasOwnProperty("description")) {
              outline.description = "";
            }
          });
        });

        setAllHeadingDetails(response?.data?.data);
        // setStoreTemplate(response?.data?.data?.[0]?.outlines);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllestimation = async () => {
    try {
      const response = await getAllEstimation();
      if (response.data.status) {
        // console.log(response.data, "data564");

        setAllEstimation(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(outlines, "estimation state");

  const htmlString = (txt) => {
    const htmlString = txt;

    const parser = new DOMParser();

    const dom = parser.parseFromString(htmlString, "text/html");

    const plainText = dom.body.textContent;

    return plainText;
  };

  // !! Previously It was used for creating Estination.

  // const previousSaveHandelEstimate = async () => {
  //   const sanitizedOutlines = outlines.map(
  //     ({ updatedAt, createdAt, ...rest }) => rest
  //   );

  //   if (copiedValue ? !copiedValue : sanitizedOutlines.length === 0) {
  //     toast.warn("Descriptions can not be empty");
  //     return;
  //   } else {
  //     const isEmptyDescription = (
  //       copiedValue ? storeTemplate.outlines : outlines
  //     ).some(
  //       (outline) =>
  //         outline.isRequired &&
  //         (outline.description === "" ||
  //           !htmlString(outline.description)?.trim())
  //     );

  //     if (isEmptyDescription) {
  //       (copiedValue ? storeTemplate.outlines : outlines).map((item, index) => {
  //         if (item.isRequired && !htmlString(item.description)?.trim()) {
  //           toast.warning(`${item.outlineName} can not be empty!`);
  //           return;
  //         }
  //       });
  //       return;
  //     }
  //   }

  //   let sanitizedArr;
  //   if (copiedValue) {
  //     sanitizedArr = storeTemplate?.outlines.map((item) => ({
  //       ...item,
  //       description: cleanHtmls(item.description),
  //     }));
  //   } else {
  //     sanitizedArr = sanitizedOutlines.map((item) => ({
  //       ...item,
  //       description: cleanHtmls(item.description),
  //     }));
  //   }
  //   console.log(sanitizedArr);

  //   let data = {
  //     contactId: id,
  //     leadId: leadId,
  //     actionId: actionId,
  //     estimateType: storeTemplate?.estimateType,
  //     estimationHeading: storeTemplate?.headingName,
  //     outlines: content,
  //   };

  //   try {
  //     const response = await createEstimation(data);
  //     // const response = "hi";
  //     if (response?.data.status) {
  //       toast("Estimation created sucessfully!");
  //       FetchActions();
  //       fetchHeadingDetails();
  //       setIsTemplateDialogeOpen((prev) => !prev);
  //       setShowEstimate((prev) => !prev);
  //       setCopiedValue(false);
  //       setStoreTemplate((prev) => (prev = null));
  //       setSelectedValue({});
  //       setStoreCurrentTemplate((prev) => (prev = null));
  //       setTemplate("");
  //       setFromCreateOne(false);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const saveHandelEstimate = async () => {
    // if (content === "<p><br></p>") {
    //   toast.warn("Description can not be empty!");
    //   return;
    // }
    // if (!htmlString(content)?.trim()) {
    //   toast("Description can not be empty !");
    //   return;
    // }
    let data = {
      contactId: id,
      leadId: leadId,
      actionId: actionId,
      estimateType: storeTemplate?.estimateType,
      estimationHeading: storeTemplate?.headingName,
      estimateDescription: cleanHtmls(content),
    };

    try {
      const response = await createEstimation(data);
      // const response = "hi";
      if (response?.data.status) {
        toast("Estimation created sucessfully!");
        setContent("");
        FetchActions();
        fetchHeadingDetails();
        setIsTemplateDialogeOpen((prev) => !prev);
        setShowEstimate((prev) => !prev);
        setCopiedValue(false);
        setStoreTemplate((prev) => (prev = null));
        setSelectedValue({});
        setStoreCurrentTemplate((prev) => (prev = null));
        setTemplate("");
        setFromCreateOne(false);
        setTemplateConfirmationOpen(false);
        setLoader((prev) => (prev = true));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const prevSaveHandelEditEstimate = async () => {
    if (allHeadingDetails?.length !== 0) {
      const [{ outlines } = {}] = allHeadingDetails?.filter(
        ({ headingName }) => {
          return headingName === actionIdEstimate?.estimationHeading;
        }
      );
      for (let i = 0; i < outlines?.length; i++) {
        const { _id, isRequired, description } = outlines[i];
        const filterpayLoad = actionIdEstimatePayload?.find(
          (item) => item._id === _id
        );

        if (filterpayLoad) {
          filterpayLoad.isRequired = isRequired;
          setActionIdEstimatePayload((prev) => {
            const updatedPayload = [...prev];
            updatedPayload[i] = {
              ...updatedPayload[i],
              isRequired: filterpayLoad.isRequired,
            };
            return updatedPayload;
          });
        } else {
          setActionIdEstimatePayload((prev) => {
            const updatedPayload = [...prev];
            if (updatedPayload[i]) {
              updatedPayload[i] = {
                ...updatedPayload[i],
                isRequired: false,
              };
              return updatedPayload;
            }
            return updatedPayload;
          });
        }
      }
    }
    const estimationId = actionIdEstimate._id;

    const descriptionsUnchanged = checkUpdateData.every((newItem, index) => {
      if (
        JSON.stringify(newItem.description) ===
        JSON.stringify(actionIdEstimatePayload[index].description.trim())
      ) {
      }
    });

    if (descriptionsUnchanged) {
      toast.warn("Nothing to update!");
      return;
    }
    if (actionIdEstimatePayload.length === 0) {
      toast.warn("Descriptions can not be empty");
      return;
    } else {
      const isEmptyDescription = actionIdEstimatePayload.some(
        (outline) =>
          outline.isRequired &&
          (outline.description === "" ||
            !htmlString(outline.description)?.trim())
      );

      if (isEmptyDescription) {
        actionIdEstimatePayload.map((item, index) => {
          if (item.isRequired && !htmlString(item.description)?.trim()) {
            toast.warning(`${item.outlineName} can not be empty!`);
            return;
          }
        });
        return;
      }
    }

    let sanitizedArr = actionIdEstimatePayload.map((item) => ({
      ...item,
      description: cleanHtmls(item.description),
    }));
    let data = {
      outlines: sanitizedArr,
    };

    try {
      const response = await updateEstimationbyId(estimationId, data);
      if (response.data.status) {
        setActionIdEstimatePayload((prev) => (prev = []));
        toast("Estimation updated sucessfully!");
        // EventBus.dispatch("EditorBus");
        setIsTemplateEditDialoge((prev) => !prev);
        // setShowEstimate((prev) => !prev);
        setStoreTemplate((prev) => (prev = null));
        setStoreCurrentTemplate((prev) => (prev = null));
        setTemplate("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const saveHandelEditEstimate = async () => {
    try {
      // if (content === "<p><br></p>") {
      //   toast.warn("Description can not be empty!");
      //   return;
      // }
      if (!htmlString(content)?.trim()) {
        toast("Description can not be empty");
        return;
      }
      if (previousContent === content) {
        toast("Nothing to update");
        return;
      }
      const estimationId = actionIdEstimate?._id;
      const response = await updateEstimationbyId(estimationId, {
        estimateDescription: cleanHtmls(content),
      });
      if (response.data.status) {
        toast("Estimation updated sucessfully!");
        setTemplate("");
        setPreviousContent("");
        setActionIdEstimatePayload((prev) => (prev = []));
        // EventBus.dispatch("EditorBus");
        setIsTemplateEditDialoge((prev) => !prev);
        // setShowEstimate((prev) => !prev);
        setStoreTemplate((prev) => (prev = null));
        setStoreCurrentTemplate((prev) => (prev = null));
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(template, "tempp");
  const getEstimationActionById = async (actionId) => {
    console.log("actionIdeeei", actionId);
    try {
      const response = await getEstimationByActionId(actionId);
      if (response.data.status) {
        console.log("actionId status", response.data);
        setActionIdEstimate((prev) => response.data?.data);
        setActionIdEstimatePayload((prev) => response.data?.data?.outlines);
        setCheckUpdateData((prev) => response.data?.data?.outlines);
        setContent(response.data?.data?.estimateDescription || "");
        setPreviousContent(response.data?.data?.estimateDescription || "");
        return response.data.data;
      }
    } catch (error) {
      console.log(error);
      setContent("");
      setPreviousContent("");
    }
  };

  console.log(actionIdEstimatePayload, "payloadddd");

  // const cleanHtmls = (html) => {
  //   const div = document.createElement("div");
  //   div.innerHTML = html;

  //   const paragraphs = div.querySelectorAll("p");

  //   paragraphs.forEach((p) => {
  //     if (p.innerHTML.trim() === "<br>") {
  //       p.remove();
  //     }
  //   });

  //   return div.innerHTML;
  // };
  const cleanHtmls = (html) => {
    const cleanTag = "<p><br></p>";

    // Trim cleanTag from the beginning
    while (html.startsWith(cleanTag)) {
      html = html.substring(cleanTag.length).trim();
    }

    // Trim cleanTag from the end
    while (html.endsWith(cleanTag)) {
      html = html.substring(0, html.length - cleanTag.length).trim();
    }

    return html;
  };

  // const handleEditorChange = (
  //   index,
  //   description,
  //   element,
  //   isEdit,
  //   isStoreTemplate
  // ) => {
  //   let updatedOutlines;
  //   const htmlString = (txt) => {
  //     const htmlString = txt;

  //     const parser = new DOMParser();

  //     const dom = parser.parseFromString(htmlString, "text/html");

  //     const plainText = dom.body.textContent;

  //     return plainText.trim();
  //   };

  //   // const descText = htmlString(description);

  //   const descText = cleanHtml(description);
  //   console.log(isEdit, descText, "eeeeeecom");

  //   if (!isEdit && !descText) {
  //     setStoreTemplate((prev) => {
  //       const newOutlines = [...prev?.outlines];
  //       newOutlines[index] = {
  //         ...newOutlines[index],
  //         description: "",
  //       };

  //       updatedOutlines = [...newOutlines];
  //       return {
  //         ...prev,
  //         outlines: newOutlines,
  //       };
  //     });
  //     setOutlines(() => updatedOutlines);

  //     // if (description === "<p><br></p>") return;
  //     // toast.warn("Only spaces are not allowed!");
  //     // return;
  //   }

  //   // if (isEdit || !descText) {
  //   if (isEdit) {
  //     console.log(isEdit, "eeeeeecom1");
  //     setActionIdEstimatePayload((prev) => {
  //       const updatedPayload = [...prev];
  //       updatedPayload[index] = {
  //         ...updatedPayload[index],
  //         description: description,
  //       };
  //       return updatedPayload;
  //     });

  //     setOutlines(actionIdEstimatePayload);

  //     console.log(actionIdEstimatePayload);
  //     return;
  //   }
  //   const cleanedContent = cleanHtmls(description);
  //   console.log(cleanedContent, "cleanedcon");
  //   setStoreTemplate((prev) => {
  //     const newOutlines = [...prev.outlines];
  //     newOutlines[index] = {
  //       ...newOutlines[index],
  //       description: description,
  //     };

  //     updatedOutlines = [...newOutlines];
  //     return {
  //       ...prev,
  //       outlines: newOutlines,
  //     };
  //   });
  //   console.log(updatedOutlines, "prev1");

  //   setOutlines(() => updatedOutlines);
  // };
  console.log(storeTemplate, "updateee");
  //propoosal methods start

  const getproposalTemplates = async () => {
    try {
      const response = await proposalTemplates();
      if (response.data.status) {
        setProposaldataTemplate(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getEstimationLatest = async () => {
    try {
      const effortEstimate = await latestEstimation(
        id,
        leadId,
        "Effort Estimate"
      );
      const costEstimate = await latestEstimation(id, leadId, "Cost Estimate");
      if (effortEstimate.data.status) {
        setEffortEstimate(effortEstimate.data.data);
      }
      if (costEstimate.data.status) {
        setCostEstimate(costEstimate.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeProposal = (event, rowIndex, columnName) => {
    const newData = [...proposalData];

    if (event.target.value && !event.target.value?.trim()) {
      toast.warn("Section name can not contain only space");
      return;
    }
    if (event.target.name === "sectionName" && event.target.value.length > 20) {
      toast.warn("Section name should not cross 20 characters");
      return;
    }

    newData[rowIndex][columnName] = event.target.value;
    console.log("newData ", newData, costEstimate);
    console.log("newData1", event.target.value, rowIndex, columnName);

    setProposalData(newData);
  };

  const handleCheckboxChange = (event, rowIndex) => {
    // Update the data based on checkbox changes
    const newData = [...proposalData];
    newData[rowIndex].skip = event.target.checked;
    setProposalData(newData);
  };

  const createaProposalPdf = async () => {
    let validationFailed = false;
    let foundObject;
    proposalData.map((item, index) => {
      if (index === 0 || index === 1) {
        const parts = item.dataTemplate.split(":");
        const targetOutlineName = parts[0]?.trim();
        const targetTemplateName = parts[1]?.trim();
        foundObject = proposaldataTemplate.find(
          (item) =>
            item.outlineName === targetOutlineName &&
            item.templateName === targetTemplateName
        );
        let outlineName = foundObject?.outlineName;
        let description = foundObject?.description;
        // proposalData[index].outlineArray = [{ outlineName, description }];
        proposalData[index].htmlTemplates = description;
        return targetOutlineName + " " + targetTemplateName;
      }
      if (index === 2) {
        const dataTemplate = item.dataTemplate;

        foundObject = effortEstimate.find(
          (item) => item.estimationHeading === dataTemplate
        );
        // proposalData[index].outlineArray = foundObject?.outlines;

        proposalData[index].htmlTemplates = foundObject?.estimateDescription;
      }

      if (index === 3) {
        const dataTemplate = item.dataTemplate;

        foundObject = costEstimate.find(
          (item) => item.estimationHeading === dataTemplate
        );
        // proposalData[index].outlineArray = foundObject?.outlines;
        proposalData[index].htmlTemplates = foundObject?.estimateDescription;
      }
    });

    const filterData = proposalData.filter((item) => !item.skip);
    filterData.map((ele) => {
      if (ele.sectionName?.trim() === "") {
        toast.warn(`${ele.section} section name can not be empty`);
        validationFailed = true;
      }
      if (ele.dataTemplate?.trim() === "") {
        toast.warn(`${ele.section} data template can not be empty`);
        validationFailed = true;
      }
    });

    // console.log("proposalData", proposalData, filterData);
    let hitApi;
    const Validate = (data) => {
      return data === "" || data === undefined || data === null ? false : true;
    };
    const decimalNumberValidate = (num) => {
      let regEx = /^\d+(?:\.\d+)?$/gm;
      let result = regEx.test(num);
      if (!result) {
        return false;
      } else {
        return true;
      }
    };
    if (!decimalNumberValidate(priceData.cost)) {
      toast.warn("Cost can not be empty");
      hitApi = false;
    }
    if (!Validate(priceData.currency)) {
      toast.warn("Currency can not be empty");
      hitApi = false;
    }
    if (!Validate(priceData.schedule)) {
      toast.warn("Schedule can not be empty");
      hitApi = false;
    }

    if (
      Validate(priceData.currency) &&
      Validate(priceData.schedule) &&
      decimalNumberValidate(priceData.cost)
    ) {
      hitApi = true;
    }
    if (!hitApi) {
      return;
    }
    if (validationFailed) {
      // validationFailed = false;
      return;
    }

    let modifyData = filterData.map(({ dataTemplate, ...rest }) => {
      return {
        section: rest.section,
        sectionName: rest.sectionName,
        isSkipped: rest.skip,
        // dataTemplates: rest.outlineArray,
        htmlTemplates: rest.htmlTemplates,
      };
    });

    let data = {
      contactId: id,
      leadId: leadId,
      actionId: actionId,
      proposals: modifyData,
    };
    console.log("proposalData", data);
    try {
      setLoaderDialog(true);
      const addPriNegoRes = await createPriceNegotiation(id, leadId, priceData);
      // if (addPriNegoRes.data.status) {
      //   setPriceData(initialPriceData);
      // }
      const response = await createLeadProposal(data);
      if (response.data.status && addPriNegoRes.data.status) {
        toast.success("Proposal created sucessfully!");
        FetchActions();
        setOpenProposalDialouge((prev) => !prev);
        setProposalData(initialProposalData);
        setPriceData(initialPriceData);
        setIsPriceNegotiation((prev) => !prev);
        setLoaderDialog(false);
      }
    } catch (err) {
      setLoaderDialog(false);
      console.log(err);
    }
  };

  const handelViewProposal = async (actionId) => {
    setLoaderDialog(true);
    const response = await getLeadProposalPDFUrlByActionId(actionId);
    if (response.data.status) {
      const { data } = response.data;
      setProposalUrl(() => response.data.data);
      data?.pdfUrl !== ""
        ? window.open(data.pdfUrl)
        : setIsProposalOpen((prev) => !prev);
      setLoaderDialog(false);
    }
  };
  console.log(proposalUrl, "url------------>");

  React.useEffect(() => {
    if (fromLeadPage) {
      if (openProposalDialouge) {
        getproposalTemplates();
        getEstimationLatest();
      }
    }
  }, [openProposalDialouge]);

  //propoosal methods end

  //cost calculation start
  // Mock function to fetch existing cost calculation data
  const fetchCostCalculationData = (id) => {
    // Replace this with API call to fetch data based on actionId
    return [
      { resourceName: "Developer", engagementType: "Monthly", amount: "1000" },
      { resourceName: "Designer", engagementType: "Weekly", amount: "500" },
    ];
  };

  // Handle opening the dialog for creation
  const handleOpenCostCalculationCreate = () => {
    setCostCalculationDialogMode("Create");
    setCostCalculationData([]); // Reset data for new creation
    setIsCostCalculationDialogOpen(true);
  };

  // Handle opening the dialog for viewing
  const handleOpenCostCalculationView = (id) => {
    // const existingData = fetchCostCalculationData(id); // Fetch data using actionId
    setCostCalculationDialogMode("View");
    // setCostCalculationData(existingData);
    setIsCostCalculationDialogOpen(true);
  };

  // Handle saving cost calculation data
  const handleSaveCostCalculation = async (data) => {
    console.log("Saved Cost Calculation Data:", id, leadId, actionId, data);
    // setIsCostCalculationDialogOpen(false);

    // Add logic to save the data (e.g., API call to save)
    try {
      const response = await storeCostCalcutedData({
        contactId: id,
        leadId: leadId,
        actionId: actionId,
        ...data,
      });
      if (response.data?.status) {
        toast.success("Cost Calculation saved successfully!");
        setIsCostCalculationDialogOpen(false);
        setCostCalculationDialogMode("");
        FetchActions();
      }
    } catch (error) {
      toast.error(error?.message ?? "Failed to save cost calculation data");
    }
  };

  // Handle updating cost calculation data
  const handleUpdateCostCalculation = async (_id, data) => {
    try {
      const response = await updateCostCalculatedData(_id, {
        contactId: id,
        leadId: leadId,
        actionId: actionId,
        ...data,
      });
      if (response.data?.status) {
        toast.success("Cost Calculation updated successfully!");
        setIsCostCalculationDialogOpen(false);
        setCostCalculationDialogMode("");
        FetchActions();
      }
    } catch (error) {
      toast.error(error?.message ?? "Failed to update cost calculation data");
    }
  };
  //cost calculation end
  React.useEffect(() => {
    if (fromLeadPage) {
      console.log("running..");
      fetchHeadingDetails();
      if (openProposalDialouge) {
        getproposalTemplates();
      }
    }
  }, []);

  //Mark as done button disabled logic
  const isMarkAsDoneDisabled = () => {
    if (!fromLeadPage) return false;

    if (toDo === "Estimate" || toDo === "Cost Estimate") {
      return !isEstimationCreated;
    }

    if (toDo === "Cost Calculation") {
      return !isCostCalculationCreated;
    }

    return false;
  };

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.

      if (active) {
        if (toDo === "Estimate") {
          setIsopenApiLoaded(true);
          const response = await estimationDetailsByType("Effort Estimate");
          setIsopenApiLoaded(false);
          if (response.data.status) {
            const { data } = response.data;
            data.forEach((elem) => {
              elem.outlines.forEach((outline) => {
                if (!outline.hasOwnProperty("description")) {
                  outline.description = "";
                }
              });
            });
            setAllHeadingEffortDetails(() => data);
            // setAllHeadingDetails(response?.data?.data);
          }
        } else if (toDo === "Cost Estimate") {
          setIsopenApiLoaded(true);
          const response = await estimationDetailsByType("Cost Estimate");
          setIsopenApiLoaded(false);
          if (response.data.status) {
            const { data } = response.data;
            data.forEach((elem) => {
              elem.outlines.forEach((outline) => {
                if (!outline.hasOwnProperty("description")) {
                  outline.description = "";
                }
              });
            });
            setAllHeadingCostDetails(() => data);
            // setAllHeadingDetails(() => data);
          }
        }
        // setOptions([...topFilms]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, fromCreateOne]);

  React.useEffect(() => {
    if (!openAutoComplete) {
      // setAllHeadingEffortDetails([]);
      // setAllHeadingCostDetails([]);
    }
  }, [openAutoComplete]);
  const sanitizedData = (description) => ({
    __html: DOMPurify.sanitize(
      htmlString(description)?.trim() ? description : "<p>No Description!</p>"
    ),
  });

  // const updateContent = React.useCallback(
  //   debounce((newContent) => {
  //     console.log("obbbj");
  //     setContent(newContent);
  //   }, 500), // Adjust the debounce delay as needed
  //   []
  // );

  const handleEditorChange = (newContent) => {
    console.log("newContent", newContent);
    if (content !== newContent) {
      setContent(newContent);
    }
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} direction="column">
          {from === constants.DASHBOARD ? (
            <Box
              onClick={() => {
                handleClick(id, leadId, actionId);
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={10} sx={{ marginTop: "0" }}>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {dynamicActionType?.displayName.singularName}:
                  </span>
                  <span style={style}>{toDo}</span>
                  <span> assigned in </span>
                  <span style={{ fontWeight: "bold" }}>
                    {actionType === constants.PROJECTS
                      ? projectTitle
                      : actionType === constants.CONTACTS
                      ? contactName
                      : actionType === constants.COMPANIES
                      ? companyName
                      : leadSummary}
                  </span>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography>
                    <span style={style}>Assigned to &nbsp;</span>
                    <span style={{ fontWeight: "bold", padding: "0" }}>
                      {isDone ? (
                        doer.name
                      ) : doer.name === authUser.name ? (
                        <span style={style}>{doer.name}</span>
                      ) : (
                        doer.name
                      )}
                    </span>

                    {Object.keys(assignedBy).length > 0 ? (
                      <>
                        <span style={{ padding: "5px" }}> by </span>
                        <span style={{ fontWeight: "bold", padding: "5px" }}>
                          {assignedBy.name === authUser.name ? (
                            <span style={style}>{assignedBy.name}</span>
                          ) : (
                            assignedBy.name
                          )}
                        </span>
                      </>
                    ) : null}
                    <span style={{ padding: "5px" }}> due on </span>
                    <span style={{ fontWeight: "bold", padding: "5px" }}>
                      {dateChange(dueDate)}
                    </span>

                    <br />
                    <span style={style}>{toDo}</span>
                    {meetLink ? (
                      meetLink !== "" ? (
                        <span style={{ marginLeft: "10px" }}>
                          <Link
                            underline="hover"
                            target="_blank"
                            rel="noopener"
                            href={`${meetLink}/${currentUser._id}`}
                          >
                            Meet Link
                          </Link>
                        </span>
                      ) : null
                    ) : null}
                    {!isDone && meetLink
                      ? (currentUser._id === doerId ||
                          currentUser._id === assignedBy.assignerId) && (
                          <IconButton
                            size="small"
                            sx={{ ml: 0.5 }}
                            onClick={() => {
                              window.navigator.clipboard.writeText(
                                `${meetLink}/shared`
                              );
                              toast("Meeting Link Copied");
                            }}
                          >
                            <ContentCopyIcon sx={{ height: "20px" }} />
                          </IconButton>
                        )
                      : null}
                  </Typography>
                </Grid>
                {/* 2nd part */}
                <Grid item xs={5}>
                  <>
                    <Grid container>
                      {(toDo?.trim() === "Estimate" ||
                        toDo === "Cost Estimate") &&
                        !isEstimationCreated &&
                        !isDone &&
                        isDoerOrAdmin && (
                          <>
                            <Grid
                              item
                              xs={8}
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              {/* {allHeadingDetails.length > 0 ? (
                                  <FormControl sx={{ width: "75%" }}>
                                    <InputLabel id="demo-simple-select-label">
                                      Select Estimate Template
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={template}
                                      label="Select Estimate Template"
                                      onChange={handleChange}
                                    >
                                      <MenuItem
                                        value={
                                          allHeadingDetails?.[0]?.headingName
                                        }
                                      >
                                        {allHeadingDetails?.[0]?.headingName}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <Typography varient="caption">
                                    Estimate template is empty please create!
                                  </Typography>
                                )} */}

                              <Autocomplete
                                id="asynchronous-demo"
                                sx={{ width: 200 }}
                                open={openAutoComplete}
                                // disabled={copiedValue ? true : false}
                                onOpen={() => {
                                  setOpenAutocomplete(true);
                                }}
                                onClose={() => {
                                  setOpenAutocomplete(false);
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option?.headingName === value?.headingName
                                }
                                // clearOnBlur={true}
                                value={selectedValue[actionId] ?? null}
                                onChange={(event, newValue) => {
                                  //  handleOptionSelect(newValue);
                                  setSelectedValue((prev) => ({
                                    ...prev,
                                    [actionId]: newValue,
                                  }));
                                  setStoreTemplate(() => ({
                                    headingName: newValue?.headingName,
                                    estimateType: newValue?.estimateType,
                                    outlines: newValue?.outlines,
                                    actionType: toDo,
                                  }));
                                  setStoreCurrentTemplate(() => ({
                                    headingName: newValue?.headingName,
                                    estimateType: newValue?.estimateType,
                                    outlines: newValue?.outlines,
                                    actionType: toDo,
                                  }));
                                  const initialContent = generateContent(
                                    newValue?.outlines
                                  );
                                  setContent(initialContent);
                                  setTemplate(() => newValue?.headingName);
                                }}
                                getOptionLabel={(option) => option.headingName}
                                // options={options}
                                options={
                                  toDo?.trim() === "Estimate" &&
                                  allHeadingEffortDetails.length
                                    ? allHeadingEffortDetails
                                    : allHeadingCostDetails.length
                                    ? allHeadingCostDetails
                                    : []
                                }
                                loading={loading}
                                key={id}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Template"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {isopenApiLoaded ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}

                                          <div
                                            onClick={() => {
                                              setStoreTemplate(
                                                (prev) => (prev = null)
                                              );
                                              setSelectedValue({});
                                              setStoreCurrentTemplate(
                                                (prev) => (prev = null)
                                              );
                                              setTemplate("");
                                              setCopiedValue(() => false);
                                            }}
                                          >
                                            {params.InputProps.endAdornment}
                                          </div>
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <EstimationMenu
                                allHeadingDetails={allHeadingDetails}
                                template={template}
                                setIsTemplateDialogeOpen={
                                  setIsTemplateDialogeOpen
                                }
                                copiedValue={copiedValue}
                                setCopiedValue={setCopiedValue}
                                fetchHeadingDetails={fetchHeadingDetails}
                                fromCreateOne={fromCreateOne}
                                setFromCreateOne={setFromCreateOne}
                                setStoreTemplate={setStoreTemplate}
                                storeTemplate={storeTemplate}
                                selectedValue={selectedValue}
                                actionId={actionId}
                                toDo={toDo}
                                setContent={setContent}
                                setLoader={setLoader}
                              />
                            </Grid>
                          </>
                        )}
                      {console.log("isProposalCreated", isProposalCreated)}
                      {toDo?.trim() === "Proposal" &&
                        !isProposalCreated &&
                        !isDone &&
                        isDoerOrAdmin && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <Button
                                sx={{ alignSelf: "center" }}
                                variant="outlined"
                                onClick={() =>
                                  setOpenProposalDialouge((prev) => !prev)
                                }
                              >
                                Create Proposal
                              </Button>
                            </Grid>
                          </>
                        )}
                      {toDo?.trim() === "Proposal" &&
                        isProposalCreated &&
                        isDoerOrAdminOrLeadSalesOwner && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <Button
                                sx={{ alignSelf: "center" }}
                                variant="outlined"
                                onClick={() => handelViewProposal(actionId)}
                              >
                                View Proposal
                              </Button>
                            </Grid>
                          </>
                        )}

                      {toDo?.trim() === "Cost Calculation" &&
                        !isCostCalculationCreated &&
                        isDoerOrAdminOrLeadSalesOwner && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Button
                              sx={{ alignSelf: "center" }}
                              variant="outlined"
                              onClick={handleOpenCostCalculationCreate}
                            >
                              Create Cost Calculation
                            </Button>
                          </Grid>
                        )}
                      {toDo?.trim() === "Cost Calculation" &&
                        isCostCalculationCreated &&
                        isDoerOrAdminOrLeadSalesOwner && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Button
                              sx={{ alignSelf: "center" }}
                              variant="outlined"
                              onClick={() =>
                                handleOpenCostCalculationView(actionId)
                              }
                            >
                              View Cost Calculation
                            </Button>
                          </Grid>
                        )}
                      {isEstimationCreated && isDoerOrAdminOrLeadSalesOwner && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              onClick={(e) =>
                                handleClickMenu(e, actionId, false)
                              }
                              sx={{ alignSelf: "center" }}
                              variant="outlined"
                              id="estimate-menu"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                            >
                              {/* <MoreHorizIcon /> */}
                              {toDo === "Estimate"
                                ? "Estimate Details"
                                : "Cost Details"}
                            </Button>
                            <Menu
                              id="estimate-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 0.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    left: 16,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "left",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem onClick={handleClose}>
                                <IconButton
                                  onClick={handleEstimateActionDialouge}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                  onClick={handleEstimateEditAction}
                                  disabled={isDone ? true : false}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={(e) =>
                                    handleClickMenu(e, actionId, true, toDo)
                                  }
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </MenuItem>
                            </Menu>

                            {/* </Icon> */}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                </Grid>
                {/* 3rd part */}
                <Grid item xs={3}>
                  <Grid container>
                    {isDone && authUser.name === doer.name ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ alignSelf: "center" }}>
                            <Button
                              color="success"
                              disableRipple
                              startIcon={<CheckCircleOutlineIcon />}
                            >
                              Done
                            </Button>
                            <span>&nbsp;&nbsp;</span>
                            <span style={style}>On </span>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {convert(new Date(doneOn))}
                            </span>
                          </div>
                        </Grid>
                      </>
                    ) : !isDone && authUser.name === doer.name ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ alignSelf: "center" }}
                            onClick={markAsDone}
                            disabled={isMarkAsDoneDisabled()}
                          >
                            MARK AS DONE
                          </Button>
                        </Grid>
                      </>
                    ) : isDone && authUser.name != doer.name ? (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ alignSelf: "center" }}>
                          <Button
                            color="success"
                            disableRipple
                            startIcon={<CheckCircleOutlineIcon />}
                          >
                            Done
                          </Button>
                          <span>&nbsp;&nbsp;</span>
                          <span style={style}>On </span>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            {convert(new Date(doneOn))}
                          </span>
                        </div>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button disableRipple size="small">
                          NOT DONE
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>

      {/** Create  */}
      {/* <BootstrapDialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={isTemplateDialogeOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {storeTemplate?.headingName} {"Template"}
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={1} style={{ overflowY: "hidden" }}>
            {storeTemplate?.outlines?.length > 0 ? (
              storeTemplate?.outlines?.map((element, index) => (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6">{element.outlineName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ReactQuill
                      style={{ padding: 0, width: "100%", minHeight: "auto" }}
                      readonly
                      value={element?.description}
                      preserveWhitespace="true"
                      placeholder="Add your description here"
                      name="description"
                      onChange={(e) =>
                        handleEditorChange(index, e, element, false)
                      }
                      className="app ql-editor"
                    />
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} sm={12}>
                <Typography textAlign="center" fontWeight="700" color="#857C7A">
                  {"Outlines are empty please add in your template"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            autoFocus
            onClick={() => {
              setIsTemplateDialogeOpen((prev) => !prev);
              setStoreTemplate((prev) => (prev = null));
              setStoreCurrentTemplate((prev) => (prev = null));
              setAllHeadingEffortDetails((prev) => []);
              setAllHeadingCostDetails((prev) => []);
              setCopiedValue(() => false); 
              setTemplate("");
              setSelectedValue({});
              setOutlines((prev) => (prev = []));
              setFromCreateOne(false);
              fetchHeadingDetails();
            }}
          >
            CANCEL
          </Button>
          <Button
            autoFocus
            onClick={saveHandelEstimate}
            disabled={storeTemplate?.outlines?.length ? false : true}
          >
            SAVE
          </Button>
        </DialogActions>
      </BootstrapDialog> */}
      <BootstrapDialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={isTemplateDialogeOpen}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {storeTemplate?.headingName} {"Template"}
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={1} style={{ overflowY: "hidden" }}>
            {/* <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              // onBlur={(newContent) => setContent(newContent)}
              onBlur={(newContent) => {
                console.log("newContent", newContent);
                if (content !== newContent) {
                  setContent(newContent);
                }
              }}
              // onChange={(newContent) => updateContent(newContent)}
            /> */}
            {loader && (
              <Grid item xs={12}>
                <Box
                  component={"div"}
                  style={{
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Grid>
            )}

            <Grid item xs={12} sx={{ display: loader ? "none" : "block" }}>
              <Editor
                apiKey="tz9fv6diqhdfspinfiqazm0afdtq9tosk6vaqj8ukejlmztv"
                value={content}
                init={{
                  plugins:
                    "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
                  toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                }}
                onEditorChange={handleEditorChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            autoFocus
            onClick={() => {
              setIsTemplateDialogeOpen((prev) => !prev);
              setStoreTemplate((prev) => (prev = null));
              setStoreCurrentTemplate((prev) => (prev = null));
              setAllHeadingEffortDetails((prev) => []);
              setAllHeadingCostDetails((prev) => []);
              setCopiedValue(() => false);
              setTemplate("");
              setSelectedValue({});
              setOutlines((prev) => (prev = []));
              setFromCreateOne(false);
              fetchHeadingDetails();
              setLoader((prev) => (prev = true));
            }}
          >
            CANCEL
          </Button>
          <Button
            autoFocus
            onClick={() => {
              if (!htmlString(content)?.trim()) {
                toast("Description can not be empty");
                setTemplateConfirmationOpen(false);
                return;
              }
              setTemplateConfirmationOpen(true);
            }}
            // disabled={storeTemplate?.outlines?.length ? false : true}
            disabled={!content ? true : false}
          >
            CREATE
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* confirmation dialouge */}
      <BootstrapDialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={isTemplateConfirmationOpen}
        // maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Confirm Template Sections
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={1} style={{ overflowY: "hidden" }}>
            Before creating the estimation, please verify if all the sections
            are included or if any are skipped
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            autoFocus
            onClick={() => {
              setTemplateConfirmationOpen(false);
              setLoader((prev) => (prev = true));
            }}
          >
            CANCEL
          </Button>
          <Button
            autoFocus
            onClick={saveHandelEstimate}
            // disabled={storeTemplate?.outlines?.length ? false : true}
            disabled={!content ? true : false}
          >
            SAVE
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* view template */}
      {/* <Dialog
        maxWidth="lg"
        open={estimateActionDialouge}
        onClose={() => {
          setExpanded(0);
          setEstimateActionDialouge((prev) => !prev);
        }}
      >
        <DialogTitle>{actionIdEstimate?.estimationHeading}</DialogTitle>
        <DialogContent>
          {console.log("actionIdEstimate", actionIdEstimate)}
          {actionIdEstimate?.outlines?.length > 0 ? (
            actionIdEstimate?.outlines.map((outline, index) => {
              const sanitizedData = () => ({
                __html: DOMPurify.sanitize(
                  htmlString(outline?.description)?.trim()
                    ? outline?.description
                    : "<p>No Description!</p>"
                ),
              });
              return (
                <>
                  <Accordion
                    key={index}
                    expanded={Expanded === index}
                    onChange={handleAccordionChange(index)}
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.03)",
                      width: "500px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <b> {outline?.outlineName} </b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={0.5}>
                        <Grid item xs={12}>
                          <Typography className="ql-editor">
                            <div
                              id="desc"
                              dangerouslySetInnerHTML={sanitizedData()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={10}></Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })
          ) : (
            <Grid item xs={12} sm={12}>
              <Typography textAlign="center" fontWeight="700" color="#857C7A">
                {
                  "Estimate details are not available please create another estimate action"
                }
              </Typography>
            </Grid>
          )}
        </DialogContent>
      </Dialog> */}

      <Dialog
        maxWidth="lg"
        open={estimateActionDialouge}
        onClose={() => {
          setExpanded(0);
          setEstimateActionDialouge((prev) => !prev);
        }}
        fullWidth
      >
        <DialogTitle>{actionIdEstimate?.estimationHeading}</DialogTitle>
        <DialogContent>
          {console.log("actionIdEstimate", actionIdEstimate)}

          {actionIdEstimate?.estimateDescription &&
          actionIdEstimate?.estimateDescription ? (
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <>{parse(actionIdEstimate?.estimateDescription)}</>
                {/* <Typography className="ql-editor">
                  <div
                    id="desc"
                    dangerouslySetInnerHTML={sanitizedData(
                      actionIdEstimate?.estimateDescription
                    )}
                  />
                </Typography> */}
              </Grid>
              <Grid item xs={10}></Grid>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12}>
              <Typography textAlign="center" fontWeight="700" color="#857C7A">
                {
                  "Estimate details are not available please create another estimate action"
                }
              </Typography>
            </Grid>
          )}
        </DialogContent>
      </Dialog>

      {/* New Edit*/}
      <BootstrapDialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={isTemplateEditDialoge}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {actionIdEstimate?.estimationHeading} {"Template"}
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={1} style={{ overflowY: "hidden" }}>
            {loader && (
              <Grid item xs={12}>
                <Box
                  component="div"
                  style={{
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Grid>
            )}
            {previousContent && previousContent?.length ? (
              <Grid item xs={12} sx={{ display: loader ? "none" : "block" }}>
                <Editor
                  apiKey="tz9fv6diqhdfspinfiqazm0afdtq9tosk6vaqj8ukejlmztv"
                  value={content}
                  init={{
                    plugins:
                      "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
                    toolbar:
                      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                  }}
                  onEditorChange={handleEditorChange}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12}>
                <Typography textAlign="center" fontWeight="700" color="#857C7A">
                  {
                    "Estimate details are not available please create another estimate action"
                  }
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setIsTemplateEditDialoge((prev) => !prev);
              setLoader((prev) => (prev = true));
            }}
            color="error"
          >
            CANCEL
          </Button>
          <Button
            autoFocus
            onClick={saveHandelEditEstimate}
            disabled={!content ? true : false}
          >
            SAVE
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* proposal content */}
      <BootstrapDialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={openProposalDialouge}
        maxWidth={800}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <h3>Create Proposal</h3>
        </DialogTitle>

        <DialogContent dividers>
          {/* <Grid container spacing={1}> */}
          <TableContainer component={Paper} maxWidth={800}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 180 }}>
                    <Typography sx={{ fontWeight: "bold" }}>Section</Typography>
                  </TableCell>
                  <TableCell sx={{ width: 370 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Section Name
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: 380 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Data Template
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: 20 }}>
                    <Typography sx={{ fontWeight: "bold" }}>Skip</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalData?.length &&
                  proposalData?.map((row, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Typography>{row.section}</Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TextField
                            label="Section Name"
                            variant="outlined"
                            placeholder="Section Name"
                            type="text"
                            name="sectionName"
                            required
                            value={row.skip ? "" : row.sectionName}
                            fullWidth
                            onChange={(event) =>
                              handleChangeProposal(event, index, "sectionName")
                            }
                            disabled={row.skip ? true : false}
                          />
                        </TableCell>
                        {(index === 0 || index === 1) && (
                          <TableCell align="left">
                            <FormControl fullWidth required>
                              <InputLabel id={`data-template-label-${index}`}>
                                Data Template
                              </InputLabel>
                              <Select
                                labelId={`data-template-label-${index}`}
                                id={`data-template-select-${index}`}
                                value={row.skip ? "" : row.dataTemplate}
                                label="Data Template"
                                onChange={(event) =>
                                  handleChangeProposal(
                                    event,
                                    index,
                                    "dataTemplate"
                                  )
                                }
                                disabled={row.skip ? true : false}
                              >
                                {proposaldataTemplate.map((ele, menuIndex) => (
                                  <MenuItem
                                    key={menuIndex}
                                    value={
                                      ele.outlineName + " : " + ele.templateName
                                    }
                                  >
                                    {ele.outlineName
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}{" "}
                                    {" : "}
                                    {ele.templateName
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                        )}

                        {row.section === "Project Details"
                          ? (index === 2 || index === 3) && (
                              <TableCell align="left">
                                <FormControl fullWidth required>
                                  <InputLabel
                                    id={`data-template-label-${index}`}
                                  >
                                    Data Template
                                  </InputLabel>
                                  <Select
                                    labelId={`data-template-label-${index}`}
                                    id={`data-template-select-${index}`}
                                    value={row.dataTemplate}
                                    label="Data Template "
                                    onChange={(event) =>
                                      handleChangeProposal(
                                        event,
                                        index,
                                        "dataTemplate"
                                      )
                                    }
                                  >
                                    {effortEstimate.length ? (
                                      effortEstimate.map((ele, ind) => (
                                        <MenuItem value={ele.estimationHeading}>
                                          {ele.estimationHeading}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem disabled value={"No options"}>
                                        {"No options"}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            )
                          : (index === 2 || index === 3) && (
                              <TableCell align="left">
                                <FormControl fullWidth required>
                                  <InputLabel
                                    id={`data-template-label-${index}`}
                                  >
                                    Data Template
                                  </InputLabel>
                                  <Select
                                    labelId={`data-template-label-${index}`}
                                    id={`data-template-select-${index}`}
                                    value={row.dataTemplate}
                                    label="Data Template"
                                    onChange={(event) =>
                                      handleChangeProposal(
                                        event,
                                        index,
                                        "dataTemplate"
                                      )
                                    }
                                  >
                                    {costEstimate.length ? (
                                      costEstimate.map((ele, ind) => (
                                        <MenuItem value={ele.estimationHeading}>
                                          {ele.estimationHeading}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem disabled value={"No options"}>
                                        {"No options"}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            )}

                        <TableCell>
                          {(index === 0 || index === 1) && (
                            <Checkbox
                              checked={row.skip}
                              onChange={(event) =>
                                handleCheckboxChange(event, index)
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography>Price Negotiation</Typography>
                  </TableCell>
                  <TableCell colSpan={4}>
                    <AddPricenegociation
                      fromCreateProposal={true}
                      priceData={priceData}
                      setPriceData={setPriceData}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* </Grid> */}
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenProposalDialouge((prev) => !prev);
              setProposalData(initialProposalData);
              setPriceData(initialPriceData);
            }}
            color="error"
          >
            CANCEL
          </Button>
          <Button
            autoFocus
            onClick={createaProposalPdf}
            // sx={{ fontSize: "20px" }}
          >
            SAVE
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* view proposal */}
      <Dialog
        fullWidth
        maxWidth={1000}
        open={isProsalOpen}
        aria-labelledby="customized-dialog-title"
        onClose={() => {}}
      >
        {/* {document.windows.open(proposalUrl)} */}
        <DialogTitle id="customized-dialog-title">
          Proposal Estimate
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setIsProposalOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid item xs={12} sm={12}>
            <Typography textAlign="center" fontWeight="700" color="#857C7A">
              {
                "Proposal estimate details are not available please create another proposal estimate"
              }
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
      {/* loaderdialouge */}
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>
      {/* Cost Calculator Dialog */}
      <CostCalculatorDialog
        open={isCostCalculationDialogOpen}
        mode={costCalculationDialogMode}
        data={costCalculationData}
        onSave={handleSaveCostCalculation}
        onClose={() => {
          setIsCostCalculationDialogOpen(false);
          setCostCalculationDialogMode("");
        }}
        actionId={actionId}
        isDone={isDone}
        setCostCalculationDialogMode={setCostCalculationDialogMode}
        onUpdate={handleUpdateCostCalculation}
      />
    </>
  );
}

export default Action;
