function calculatePercentage(basePrice = 0, percentage = 0) {
  return (basePrice * percentage) / 100;
}

function calculateCost(calculatorSettingData = [], basePrice = 0) {
  console.log("basePrice", basePrice);
  if (!calculatorSettingData.length) return 0;
  const calculatedValue = {};
  const calculatorSetting = calculatorSettingData;

  function calculateMathematicalExpressions(exp) {
    console.log("exp 12", exp);
    let count = 0,
      index = 0;
    const symbols = ["+", "-", "*", "/"];
    while (index < exp.length) {
      if (
        symbols.includes(exp[index]) &&
        (Number(exp[index + 1]) || exp[index + 1].includes("%"))
      ) {
        //!! NOTE :: commenting this part, now have to calculate the percentage from current value not from base price
        // const nextIndexValue = exp[index + 1].includes("%")
        //   ? calculatePercentage(
        //       basePrice,
        //       Number(exp[index + 1].replaceAll("%", ""))
        //     )
        //   : Number(exp[index + 1]);
        const nextIndexValue = exp[index + 1].includes("%")
          ? exp[index + 1]
          : Number(exp[index + 1]);
        if (exp[index] === "+") {
          if (
            typeof nextIndexValue === "string" &&
            nextIndexValue?.includes("%")
          ) {
            count += calculatePercentage(
              count,
              Number(nextIndexValue.replaceAll("%", ""))
            );
          } else {
            count += nextIndexValue;
          }
        } else if (exp[index] === "-") {
          if (
            typeof nextIndexValue === "string" &&
            nextIndexValue?.includes("%")
          ) {
            count -= calculatePercentage(
              count,
              Number(nextIndexValue.replaceAll("%", ""))
            );
          } else {
            count -= nextIndexValue;
          }
        } else if (exp[index] === "*") {
          if (
            typeof nextIndexValue === "string" &&
            nextIndexValue?.includes("%")
          ) {
            count = calculatePercentage(
              count,
              Number(nextIndexValue.replaceAll("%", ""))
            );
          } else {
            count *= nextIndexValue;
          }
        } else if (exp[index] === "/") {
          if (
            typeof nextIndexValue === "string" &&
            nextIndexValue?.includes("%")
          ) {
            count /= calculatePercentage(
              count,
              Number(nextIndexValue.replaceAll("%", ""))
            );
          } else {
            count /= nextIndexValue;
          }
        } else {
          count += nextIndexValue;
        }
        index++;
      } else if (
        !count &&
        typeof parseInt(exp[index]) === "number" &&
        Number(exp[index])
      ) {
        count = Number(exp[index]);
        index++;
      } else if (!count && exp[index].includes("%")) {
        count = calculatePercentage(
          basePrice,
          Number(exp[index].replaceAll("%", ""))
        );
        index++;
      } else {
        index++;
      }
    }
    console.log("count 100", count);
    return count + "";
  }

  function provideValueBySerialNumber(slNum = 0) {
    if (slNum === 1) return basePrice;
    const details = calculatorSetting.find(
      ({ serialNumber }) => serialNumber === slNum
    );
    if (details) {
      return details.value;
    }
    return "0";
  }

  function separateByExpression(exp = "", fromBracket = false) {
    const result = [];
    const symbols = ["+", "-", "*", "/"];
    let prevIndex = 0,
      currentIndex = 0;
    while (currentIndex < exp.length) {
      if (symbols.includes(exp[currentIndex])) {
        const slicedData = exp.slice(prevIndex, currentIndex);
        if (fromBracket) {
          if (slicedData.includes(".")) {
            result.push(slicedData);
          } else {
            result.push(
              calculatedValue[exp.slice(prevIndex, currentIndex)] ??
                provideValueBySerialNumber(
                  parseInt(exp.slice(prevIndex, currentIndex))
                )
            );
          }
        }
        if (!fromBracket) {
          result.push(slicedData);
        }

        result.push(exp[currentIndex]);
        prevIndex = currentIndex + 1;
      }
      currentIndex++;
    }

    const restSlicedData = exp.slice(prevIndex, currentIndex);
    if (fromBracket) {
      if (restSlicedData.includes(".")) {
        result.push(restSlicedData);
      } else {
        result.push(
          calculatedValue[exp.slice(prevIndex, currentIndex)] ??
            provideValueBySerialNumber(
              parseInt(exp.slice(prevIndex, currentIndex))
            )
        );
      }
    }

    if (!fromBracket) {
      result.push(restSlicedData);
    }
    return result;
  }

  function provideBracketInnerData(startBracketIndex, originalString) {
    for (
      let index = startBracketIndex;
      index < originalString.length;
      index++
    ) {
      if (originalString[index] === ")") {
        return {
          endBracketIndex: index,
          innerData: calculateMathematicalExpressions(
            separateByExpression(
              originalString.slice(startBracketIndex + 1, index),
              true
            )
          ),
        };
      }
    }

    return {};
  }

  function makeBracketLess(calculateTemplate) {
    let result = "";
    for (let i = 0; i < calculateTemplate.length; i++) {
      if (calculateTemplate[i] === "(") {
        const { endBracketIndex, innerData } = provideBracketInnerData(
          i,
          calculateTemplate
        );
        i = endBracketIndex;
        result += innerData;
      } else if (
        typeof parseInt(calculateTemplate[i]) === "number" &&
        parseInt(calculateTemplate[i])
      ) {
        result +=
          calculatedValue[calculateTemplate[i]] ??
          provideValueBySerialNumber(+calculateTemplate[i]);
      } else {
        result += calculateTemplate[i];
      }
    }

    return result;
  }

  calculatorSetting.forEach(({ serialNumber = 0, source = "", value = "" }) => {
    if (source.trim().toLowerCase() === "calculate") {
      const endResult = makeBracketLess(value?.trim().replaceAll(" ", ""));
      calculatedValue[serialNumber + ""] = calculateMathematicalExpressions(
        separateByExpression(endResult, false)
      );
    }
  });
  console.log("calculatedValue", calculatedValue);
  const values = Object.values(calculatedValue);
  const result = values[values.length - 1];

  return result;
}

export { calculateCost };
