import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AttributeTab from "./AttributeTab";
import ViewEmailTemplate from "../EmailTemplate/ViewEmailTemplate";
import ProposalTemplate from "./ProposalTemplate";
import ActionNameTab from "../../components/ActionNameTab/ActionNameTab";
import EstimateTemplate from "./EstimateTemplate";
import CallHippoCredentials from "./CallHippoCredentials";
import DropReasonTabPanel from "./DropReasonTabPanel";
import ConversionRate from "./ConversionRate";
import ResourceTab from "./ResourceTab";
import CalculatorSetting from "./CalculatorSetting";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomizeTab({
  entityName,
  attributeList,
  setAttributeList,
  isEntityDialougeOpne,
  setIsEntityDialougeOpne,
  getEntityname,
  inputChangeData,
  setInputChangeData,
  hitApi,
  setIsAttributeListFetched,
  emailTemplateTab,
}) {
  console.log("emailTemplateTab", emailTemplateTab);
  const [value, setValue] = React.useState(
    emailTemplateTab ? emailTemplateTab : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
        >
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>Attribute</Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={<Typography sx={{ fontSize: "1.2rem" }}>Action</Typography>}
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>
                Email Template
              </Typography>
            }
            {...a11yProps(2)}
            // onClick={() => navigate(`/emailtemplate`)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>
                Proposal Template
              </Typography>
            }
            {...a11yProps(3)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>
                Estimate Template
              </Typography>
            }
            {...a11yProps(4)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>
                Call Credential
              </Typography>
            }
            {...a11yProps(5)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>Drop Reason</Typography>
            }
            {...a11yProps(6)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>
                Conversion Rate
              </Typography>
            }
            {...a11yProps(7)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>Resources</Typography>
            }
            {...a11yProps(8)}
          />
          <Tab
            label={
              <Typography sx={{ fontSize: "1.2rem" }}>
                Calculator Settings
              </Typography>
            }
            {...a11yProps(9)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AttributeTab
          entityName={entityName}
          attributeList={attributeList}
          setAttributeList={setAttributeList}
          isEntityDialougeOpne={isEntityDialougeOpne}
          setIsEntityDialougeOpne={setIsEntityDialougeOpne}
          getEntityname={getEntityname}
          inputChangeData={inputChangeData}
          setInputChangeData={setInputChangeData}
          hitApi={hitApi}
          setIsAttributeListFetched={setIsAttributeListFetched}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ActionNameTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ViewEmailTemplate />
        {/* {navigate(`/emailtemplate`)} */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ProposalTemplate />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <EstimateTemplate />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <CallHippoCredentials />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <DropReasonTabPanel />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <ConversionRate />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8}>
        <ResourceTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={9}>
        <CalculatorSetting />
      </CustomTabPanel>
    </Box>
  );
}
