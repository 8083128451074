import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { filter } from "lodash";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormLabel,
  Container,
  CardContent,
  Box,
  Fab,
  ListItemText,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { addUser, getUsers, removeUser } from "../../services/users.service";
import { getProjects } from "../../services/projects.service";
import { ToastContainer, toast } from "react-toastify";
import Page from "../../components/UI/Page";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Scrollbar from "../../components/UI/Scrollbar";
import Iconify from "../../components/UI/Iconify";
import SearchNotFound from "../../components/SearchNotFound";

import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import MultipleSelectPlaceholder from "../../components/SearchDropDown/SearchDropDownContact";
import SearchUser from "../../components/SearchDropDown/SearchDropDownUser";
import SearchProject from "../../components/SearchDropDown/SearchDropDownProject";
import { useMatchCollectionName } from "../../utils/filterObject";
import { useDynamicContactTitle } from "../Contacts/Contacts";
import { useDynamicLeadTitle } from "../Leads/Leads";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProjectInvoiceAction from "../../components/Projects/ProjectInvoiceAction";

export const useDynamicProjectTitle = () => {
  let matchedCollection = useMatchCollectionName("projects");
  return matchedCollection;
};

export default function Projects() {
  const contactDynamicTitle = useDynamicContactTitle();

  const [open, setOpen] = React.useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [gender, setGender] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [role, setRole] = React.useState(null);
  const [password, setPassword] = React.useState("");

  const [projects, setProjects] = useState([]);
  const [tempProjects, settempProjects] = useState([]);

  useEffect(() => {}, []);

  const [count, setCount] = useState(1);

  //This variable is used for UI pagination
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [pageCount, setPageCount] = useState(1);

  const [order, setOrder] = useState("");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [column, setColumn] = useState("Title");
  const [typeText, setTypeText] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleDeletePopupOpen = (_id) => {
  //   setDeletePopupOpen({
  //     state: true,
  //     onSuccess: async () => {
  //       console.log("_id", _id);
  //       try {
  //         const response = await removeUser(_id);
  //         if (response.data.status === true) {
  //           toast("Project removed successfully");
  //           setProjects([]);
  //           setPageCount(1);
  //           fetchProjects();
  //           console.log(projects);
  //         }
  //         handleClose();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     },
  //   });
  //   return _id;
  // };

  const handleClose = () => {
    setOpen(false);
    setDeletePopupOpen(false);
  };

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    { id: "title", label: "Title", alignRight: false },
    { id: "techStack", label: "Techstack", alignRight: false },
    {
      id: "name",
      label: `${contactDynamicTitle?.displayName.singularName} name`,
      alignRight: false,
    },
    // { id: "country", label: "Country", alignRight: false },
    { id: "status", label: "Status", alignRight: true },
    { id: "action", label: "Action", alignRight: true },
  ];

  let projectStatus = ["Waiting to start", "Started", "Paused", "Closed"];
  const [selectStatus, setSelectStatus] = React.useState([]);
  // ----------------------------------------------------------------------

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  // const [contacts, setContacts] = useState([]);
  // const [searchedContacts, setSearchedContacts] = useState([...contacts]);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSetRole = (e) => {
  //   console.log(e.target.name, e.target.value);
  //   if (e.target.value === "ADMIN") {
  //     setRole("ADMIN");
  //   }
  //   if (e.target.value === "SALES") {
  //     setRole("SALES");
  //   }
  //   if (e.target.value === "DELIVERY_LEAD") {
  //     setRole("DELIVERY_LEAD");
  //   }
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = projects.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (projectId) => {
    console.log("Inside handle click", projectId);
    navigate(`/projects/${projectId}`);
  };

  const handleChangePage = (event, newPage) => {
    console.log("Inside handle change page", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > projects.length &&
      projects.length < count
    ) {
      console.log("Fetching new data");
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    console.log(event.target.value);
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const filteredProjects = applySortFilter(
    projects,
    getComparator(order, orderBy),
    filterName
  );

  // const handleDelete = async (_id) => {
  //   console.log("handle delete called for" + _id);
  //   handleDeletePopupOpen(_id);
  // };

  // const handleCreate = async () => {
  //   try {
  //     const response = await addUser({
  //       name,
  //       email,
  //       dob,
  //       gender,
  //       role,
  //       phone: phoneNumber,
  //       password,
  //     });
  //     if (response.data.status === true) {
  //       toast("Pro created successfully");
  //       setProjects([]);
  //       setPageCount(1);
  //       fetchProjects();
  //       console.log(projects);
  //       console.log("Project created");
  //       handleClose();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  function fetchProjects(fromStatusFilter) {
    console.log("fromStatusFilter", fromStatusFilter);
    if (!typeText || fromStatusFilter) {
      console.log("fromStatusFilterinside", fromStatusFilter);
      setIsLoading(true);
      getProjects(pageCount)
        .then((response) => {
          if (response.data.status) {
            console.log("filterresponse", response);
            setProjects((prevState) => [
              ...prevState,
              ...response.data.data.allProjects,
            ]);
            settempProjects((prev) => [...response.data.data.allProjects]);
            setCount(response.data.data.count);
            setIsLoading(false);
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });
    }
  }

  const printTechStack = (val) => {
    let temp = "";
    val.map((element, index) => {
      if (index !== val.length - 1) {
        temp = temp + element.name + " | ";
      } else {
        temp = temp + element.name;
      }
    });
    return temp;
  };
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeText]);
  const isUserNotFound = filteredProjects.length === 0;

  const projectDynamicTitel = useDynamicProjectTitle();

  return (
    <Page>
      <Container>
        <ToastContainer />
        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Projects
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/projects/createProject"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Create Project
          </Button>
        </Stack> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ p: 0, m: 0 }} elevation={5} variant="outlined">
              <CardContent>
                {/* <Grid container spacing={0}>
                  <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" gutterBottom="false" color="primary" sx={{ marginBottom: 0 }}>
                      PROJECTS
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to="/projects/createProject"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      Create Project
                    </Button>
                  </Grid>
                </Grid> */}

                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <SearchProject
                      projects={projects}
                      setProjects={setProjects}
                      tempProjects={tempProjects}
                      fetchProjects={fetchProjects}
                      projectStatus={projectStatus}
                      setCount={setCount}
                      column={column}
                      setColumn={setColumn}
                      typeText={typeText}
                      setTypeText={setTypeText}
                      setPage={setPage}
                      setPageCount={setPageCount}
                      setIsLoading={setIsLoading}
                      setSelectStatus={setSelectStatus}
                      selectStatus={selectStatus}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    {/* <Button
                      variant="contained"
                      component={RouterLink}
                      to="/projects/createProject"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      {`Create ${projectDynamicTitel?.displayName.singularName}`}
                    </Button> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              elevation={3}
              variant="outlined"
              sx={{ paddingRight: 2, paddingLeft: 2 }}
            >
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={count}
                      // numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredProjects
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((project) => {
                          console.log("projects: ", projects);

                          // const { _id, name, email, role, phone, active_status } =
                          const {
                            _id,
                            title,
                            techStack,
                            contact,
                            active_status,
                            status,
                          } = project;
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              sx={{ cursor: "pointer" }}
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              onClick={() => {
                                handleClick(_id);
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    marginLeft="20px"
                                  >
                                    {title}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>{printTechStack(techStack)}</TableCell>
                              {/* <TableCell align="left">
                            {role === "DELIVERY_LEAD" ? "DELIVERY LEAD" : role}
                          </TableCell> */}
                              <TableCell align="left">{contact.name}</TableCell>
                              <TableCell align="center">
                                {status?.name}
                              </TableCell>
                              {/* <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell> */}

                              {/* <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell> */}
                              <TableCell align="center">
                                {/* <IconButton
                                // onClick={handleClickMenu}
                                // disabled={!activeStatus}
                                >
                                  <MoreVertIcon />
                                </IconButton> */}

                                <ProjectInvoiceAction
                                  key={_id}
                                  projectId={_id}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {/* {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )} */}

                    {isLoading || isUserNotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            {isLoading ? (
                              <CircularProgress />
                            ) : (
                              <SearchNotFound searchQuery={filterName} />
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Grid>

          {/* <Box
            sx={{ position: "fixed", bottom: 16, right: 16 }}
            component={RouterLink}
            to="/projects/createProject"
          >
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Box> */}
        </Grid>
      </Container>
    </Page>
  );
}
