import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  IconButton,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCurrencies } from "../../../services/common.service";
import { getBanks, getCompany } from "../../../services/projects.service";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

function DecidingFactor({
  setHomeCompany,
  setHomeAccount,
  fromLeadPage,
  placeholder,
  label,
  name,
  value,
  requirements,
  handleCostAssignmentDialogState,
  addResourceButton,
  isCreateMode,
  handleIncludeGst,
}) {
  console.log(requirements.gstApplicable);
  const [currencies, setCurrency] = useState([]);
  const [company, setCompany] = useState(null);
  const [banks, setBank] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await getCurrencies();
        if (response.data.status) {
          setCurrency(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const getBankData = async () => {
    try {
      const response = await getBanks();
      if (response.data.status) {
        const data = response.data.data;

        console.log("data1111", data);
        setBank(
          data?.map((elem) => ({
            id: elem._id,
            name: elem.name,
            country: elem.country,
          }))
        );
        // setHomeAccount(
        //   data?.map((elem) => ({
        //     id: elem._id,
        //     name: elem.name,
        //     country: elem.country,
        //   }))
        // );
        // setGstValue({ ...data[0] });
        // setEditGstValue({ ...data[0] });

        // toast("GST fetched successfully");
        // setGSTFetched(true);
      }
    } catch (error) {}
  };
  const getCompanyData = async () => {
    try {
      const response = await getCompany();
      if (response.data.status) {
        const data = response.data.data;

        console.log("data1111", data);
        setCompany(
          data?.map((elem) => ({
            id: elem._id,
            name: elem.name,
            country: elem.country,
          }))
        );
        // setHomeCompany(
        //  company
        // );

        // setGstValue({ ...data[0] });
        // setEditGstValue({ ...data[0] });

        // toast("GST fetched successfully");
        // setGSTFetched(true);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBankData();
    getCompanyData();
  }, []);
  console.log(requirements, "event value");
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <TextField
        sx={{
          width:
            requirements.mode === "fixed" && !requirements.revenues.length
              ? "35%"
              : "50%",
        }}
        fullWidth
        type="number"
        name={name}
        value={value}
        onChange={handleCostAssignmentDialogState}
        label={label}
        placeholder={placeholder}
        disabled={
          !isCreateMode &&
          requirements.mode !== "fixed" &&
          requirements.numberofResources
        }
      />
      <FormControl
        sx={{
          width:
            requirements.mode === "fixed" && !requirements.revenues.length
              ? "35%"
              : "50%",
        }}
      >
        <InputLabel>Currency</InputLabel>
        <Select
          label="Currency"
          name="currency"
          value={requirements.currency}
          onChange={handleCostAssignmentDialogState}
        >
          {currencies.length
            ? currencies.map((currency, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={`${currency?.code} (${currency?.symbol})`}
                  >
                    {`${currency?.code} (${currency?.symbol})`}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width:
            requirements.mode === "fixed" && !requirements.revenues.length
              ? "35%"
              : "50%",
        }}
      >
        <InputLabel>Company</InputLabel>
        <Select
          label="Company"
          name="company"
          value={requirements.company}
          data-testid="companyName"
          onChange={(e) => handleCostAssignmentDialogState(e, company)}
        >
          {company
            ? company.map((comp, index) => {
                return (
                  <MenuItem key={index} value={comp.name}>
                    {comp.name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width:
            requirements.mode === "fixed" && !requirements.revenues.length
              ? "35%"
              : "50%",
        }}
      >
        <InputLabel>Bank Accounts</InputLabel>
        <Select
          label="Bank Accounts"
          name="account"
          value={requirements.account}
          onChange={(e) => handleCostAssignmentDialogState(e, banks)}
        >
          {banks
            ? banks.map((bank, index) => {
                return (
                  <MenuItem key={index} value={bank.name}>
                    {bank.name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
      {/* {isSameCompany && !fromLeadPage ? (
        isEdit ? (
          <IconButton
            onClick={() => {
              setIsEdit(false);
              setIsReadOnly(false);
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <>
            <IconButton
              onClick={() => {
                console.log("cancel");
                setIsEdit(true);
                setIsReadOnly(true);
              }}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                console.log("cancel");
                setIsEdit(true);
                setIsReadOnly(true);
              }}
            >
              <SaveIcon />
            </IconButton>
          </>
        )
      ) : null}
      //TODO :: Condition check on the basis of company's country
      */}
      {
        //   companyDetails?.companyCountry.toLowerCase() === "india" ||
        // (requirements.currency === "INR (₹)" &&
        //       !companyDetails?.companyCountry)
        requirements.gstApplicable ? (
          <FormControl
            sx={{
              width:
                requirements.mode === "fixed" && !requirements.revenues.length
                  ? "35%"
                  : "45%",
            }}
          >
            <FormGroup sx={{ marginTop: "6px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleIncludeGst(e);
                    }}
                    checked={requirements.gstIncluded}
                  />
                }
                label="+GST"
              />
            </FormGroup>
          </FormControl>
        ) : null
      }
      {/* <Checkbox
        name="gstIncluded"
        checked={requirements.gstIncluded}
        onChange={handleCostAssignmentDialogState}
      />{" "} */}
      {requirements.mode === "fixed" && !requirements.revenues.length
        ? addResourceButton
        : null}
      {/* {requirements.mode !== "fixed" && !requirements.tmRevenues.length
        ? addResourceButton
        : null} */}
    </Stack>
  );
}

export default DecidingFactor;
