import {
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toast } from "react-toastify";
import {
  createPriceNegotiation,
  getPriceNegociation,
} from "../../services/leads.service";
import { getCurrencies } from "../../services/common.service";

let hitApi = true;
export default function AddPricenegociation({
  id,
  conId,
  getAllPriceNegotiationDetails,
  priceAndDetailsAdded,
  setPriceAndDetailsAdded,
  // resourceDescription,
  fileDialogOpen,
  uploadableFiles,
  clearFilesView,
  backDropView,
  backDropHide,
  setIactionedApiCalled,
  fetchLeadDetails,
  setUpdateLeadCalled,
  fromCreateProposal,
  priceData,
  setPriceData,
}) {
  const [currencies, setCurrencies] = useState([]);
  const filter = createFilterOptions();
  const initialState = {
    // resource_description: "",
    // rate_per_unit: "",
    currency: "",
    // number_of_resources: 0,
    schedule: "",
    cost: "",
  };
  const [formdata, setFormData] = useState(initialState);

  const schedules = ["Hourly", "Daily", "Weekly", "Monthly", "Yearly", "Fixed"];

  const handleRatePerUnitChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  //handle change
  const handleChange = (e) => {
    if (e.target.value.length > 10) {
      toast.warn("Cost can not be more than 10 digit!");
      return;
    }
    if (fromCreateProposal) {
      setPriceData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  //Validate
  const Validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };

  const ValidateZero = (data) => {
    if (parseInt(data) === 0) {
      return false;
    }
    return true;
  };

  const decimalNumberValidate = (num) => {
    let regEx = /^\d+(?:\.\d+)?$/gm;
    let result = regEx.test(num);
    if (!result) {
      return false;
    } else {
      return true;
    }
  };

  //Post API Call

  const handleAddPriceNegociation = async () => {
    let tempObj = { ...formdata };
    // if (!ValidateZero(tempObj.number_of_resources)) {
    //   toast("Resource Count can not be empty Or `0`");
    //   hitApi = false;
    // }
    // if (!decimalNumberValidate(tempObj.rate_per_unit)) {
    //   toast("Special Symbols, Characters & Invalid Decimal Number Not Allowed");
    //   hitApi = false;
    // }
    if (!Validate(tempObj.currency)) {
      toast("Currency can not be empty");
      hitApi = false;
    }
    if (!Validate(tempObj.schedule)) {
      toast("Schedule can not be empty");
      hitApi = false;
    }
    if (!decimalNumberValidate(tempObj.cost)) {
      toast(
        "Special Symbols, Characters & Invalid Decimal Number Not Allowed For Cost"
      );
      hitApi = false;
    }
    // if (!Validate(tempObj.resource_description)) {
    //   toast("Resource Description can not be empty");
    //   hitApi = false;
    // }
    if (
      Validate(tempObj.currency) &&
      // ValidateZero(tempObj.number_of_resources) &&
      // decimalNumberValidate(tempObj.rate_per_unit) &&
      // Validate(tempObj.resource_description) &&
      Validate(tempObj.schedule) &&
      decimalNumberValidate(tempObj.cost)
    ) {
      hitApi = true;
    }
    try {
      if (hitApi) {
        backDropView();
        const response = await createPriceNegotiation(
          conId,
          id,
          tempObj,
          uploadableFiles
        );
        if (response.data.status) {
          getAllPriceNegotiationDetails();
          setPriceAndDetailsAdded(!priceAndDetailsAdded);
          toast("Details For Price Negotiation Added  Successfully");
          setFormData(initialState);
          clearFilesView();
          backDropHide();

          setUpdateLeadCalled((prevState) => (prevState = false));
          setIactionedApiCalled((prev) => !prev);
        } else {
          clearFilesView();
          setFormData(initialState);
          backDropHide();
        }
      }
    } catch (error) {
      console.log(error);
      backDropHide();
      clearFilesView();
      setFormData(initialState);
      toast(error.response.data.data[0]);
    }
  };

  useEffect(() => {
    getCurrencies()
      .then((res) => {
        if (res.data.status) {
          setCurrencies(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Grid
        container
        spacing={1}
        // sx={{
        //   marginTop: fromCreateProposal ? "10px" : "0px",
        //   display: fromCreateProposal ? "flex" : "",
        // }}
      >
        {/* {fromCreateProposal && (
          <Grid item xs={2.1} sx={{ alignSelf: "center", marginLeft: "15px" }}>
            {" "}
            <Typography>Price Negotiation</Typography>
          </Grid>
        )} */}

        <Grid item xs={fromCreateProposal ? 11 : 9}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                type="number"
                name="cost"
                value={fromCreateProposal ? priceData.cost : formdata.cost}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                placeholder="Cost"
                label="Cost"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Autocomplete
                size="sm"
                value={
                  formdata.resource_description
                    ? formdata.resource_description
                    : ""
                }
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setFormData((prev) => {
                      const temp = { ...prev };
                      temp.resource_description = newValue.trim();
                      return temp;
                    });
                  } else if (newValue && newValue.inputValue) {
                    console.log(newValue, "inputV", newValue.inputValue);

                    // Create a new value from the user input
                    // setValue();
                    setFormData((prev) => {
                      const temp = { ...prev };
                      temp.resource_description = newValue.inputValue.trim();
                      return temp;
                    });
                  } else {
                    // setValue(newValue);
                    setFormData((prev) => {
                      const temp = { ...prev };
                      temp.resource_description = newValue
                        ? newValue.resource_description.trim()
                        : "";
                      return temp;
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  console.log(inputValue);
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.resource_description
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      resource_description: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={resourceDescription.filter((desc) => {
                  return desc.resource_description !== "";
                })}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  // return option.title;
                  return option.resource_description;
                }}
                renderOption={(props, option) => (
                  <li {...props} style={{ borderColor: "white" }}>
                    {option.resource_description}
                  </li>
                )}
                // sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="Resource Description" />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={3}>
              <TextField
                label="Rate Per Unit"
                type="text"
                name="rate_per_unit"
                value={formdata.rate_per_unit}
                onChange={handleRatePerUnitChange}
                variant="outlined"
                fullWidth
              />
            </Grid> */}
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <InputLabel id="demo-select-small">Currency</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={
                    fromCreateProposal ? priceData.currency : formdata.currency
                  }
                  label="Currency"
                  onChange={(e) => {
                    if (fromCreateProposal) {
                      setPriceData((prev) => {
                        const temp = { ...prev };
                        temp.currency = e.target.value;
                        return temp;
                      });
                      return;
                    }
                    setFormData((prev) => {
                      const temp = { ...prev };
                      temp.currency = e.target.value;
                      return temp;
                    });
                  }}
                >
                  {currencies?.map((currency, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={`${currency?.code} (${currency?.symbol})`}
                      >
                        {`${currency?.code} (${currency?.symbol})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={3}>
              <TextField
                label="Resource Count"
                type="number"
                name="number_of_resources"
                value={formdata.number_of_resources}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid> */}
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <InputLabel id="demo-select-small">Schedule</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={
                    fromCreateProposal ? priceData.schedule : formdata.schedule
                  }
                  label="Schedule"
                  onChange={(e) => {
                    if (fromCreateProposal) {
                      setPriceData((prev) => {
                        const temp = { ...prev };
                        temp.schedule = e.target.value;
                        return temp;
                      });
                      return;
                    }
                    setFormData((prev) => {
                      const temp = { ...prev };
                      temp.schedule = e.target.value;
                      return temp;
                    });
                  }}
                >
                  {schedules.map((schedule, index) => {
                    return (
                      <MenuItem key={index} value={schedule}>
                        {schedule}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {fromCreateProposal ? (
          <></>
        ) : (
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1.5}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                startIcon={<UploadFileIcon />}
                sx={{ width: "50%" }}
                onClick={fileDialogOpen}
              >
                UPLOAD
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleAddPriceNegociation}
                startIcon={<AddCircleIcon />}
                sx={{ width: "50%" }}
              >
                ADD
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
}
