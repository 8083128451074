const apis = require("./api.requests");
const path = "/costcalculator/calculatorSetting/v1";
const calculationPath = "/costcalculator/costcalculation/v1";

async function getAllCalculatorSettings() {
  return await apis.hitGetApi(`${path}/`);
}

async function updateCalculatorSetting(settingId, body) {
  return await apis.hitPutApi(`${path}/${settingId}`, body);
}

async function storeCostCalcutedData(body) {
  return await apis.hitPostApi(`${calculationPath}/`, body);
}

async function getCostCalcutedData(actionId) {
  return await apis.hitGetApi(`${calculationPath}/action/${actionId}`);
}

async function updateCostCalculatedData(id, body) {
  return await apis.hitPutApi(`${calculationPath}/${id}`, body);
}

export {
  getAllCalculatorSettings,
  updateCalculatorSetting,
  storeCostCalcutedData,
  getCostCalcutedData,
  updateCostCalculatedData,
};
