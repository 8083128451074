import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getCurrencies } from "../../services/common.service";
import { getResources } from "../../services/resources.service";
import { getCostCalcutedData } from "../../services/calculatorSetting.service";
import { CalculatorContext } from "../../context/calculatorsetting.context";
import { calculateCost } from "../../utils/costCalculator.utils";

const CostCalculatorDialog = ({
  open,
  mode,
  data = [],
  onSave,
  onClose,
  actionId,
  isDone,
  setCostCalculationDialogMode,
  onUpdate,
}) => {
  const { getCalculatorSetting, calculatorSettings } =
    useContext(CalculatorContext);
  const initialRows = data;
  const initialType = "T & M";
  // const initialCurrency = "INR";
  const initialConversionRate = "";
  const [rows, setRows] = useState(initialRows);
  const [type, setType] = useState(initialType);
  const [currency, setCurrency] = useState("");
  const [conversionRate, setConversionRate] = useState(initialConversionRate);
  const [currencies, setCurrencies] = useState([]);
  const [resources, setResources] = useState([]);
  const [total, setTotal] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [costCalculationId, setCostCalculationId] = useState("");
  const [resourceCalculatedFullAmount, setResourceCalculatedFullAmount] =
    useState({});

  const isViewMode = mode === "View" ? true : mode === "Edit" ? false : false;

  useEffect(() => {
    getCalculatorSetting(type);
  }, [type]);

  // Fetch currencies using the provided function
  useEffect(() => {
    if (open) {
      getCurrencies()
        .then((res) => {
          if (res.data.status) {
            const fetchedCurrencies = res.data.data;
            setCurrencies(fetchedCurrencies);

            // Set default currency to "INR (₹)" if it exists
            const defaultCurrency = fetchedCurrencies.find(
              (c) => c.code === "INR"
            );
            if (mode === "Create") {
              if (defaultCurrency) {
                setCurrency(
                  // `${defaultCurrency.code} (${defaultCurrency.symbol})`
                  defaultCurrency._id
                );
                setConversionRate(defaultCurrency.conversionRate || 1);
              }
            }
          }
        })
        .catch((err) => console.error("Error fetching currencies:", err));
    }
  }, [open]);

  // Fetch resources using the provided function
  useEffect(() => {
    getResources()
      .then((res) => {
        if (res?.data?.status) {
          if (mode === "Create") {
            setResources(res?.data?.data.filter((r) => r.status));
            return;
          }
          setResources(res?.data?.data); // Store the fetched resources
        }
      })
      .catch((err) => console.error("Error fetching resources:", err));
  }, [mode]);
  const handleCurrencyChange = (e) => {
    const selectedCurrency = e.target.value;
    setCurrency(selectedCurrency);

    // Find the selected currency's conversion rate
    const currencyData = currencies.find(
      // (c) => `${c.code} (${c.symbol})` === selectedCurrency
      (c) => c._id === selectedCurrency
    );
    setConversionRate(currencyData?.conversionRate || 1); // Default to 1 if not found
  };

  const handleConversionRateChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && parseFloat(value) > 0) {
      setConversionRate(value);
    }
  };

  const handleAddRow = () => {
    let incompleteRow = null;
    const isTypeFIxed = type.toLowerCase() === "fixed";
    if (isTypeFIxed) {
      incompleteRow = rows.find(
        (row) =>
          !row.resourceId || !row.engagementType || !row.amount || !row.quantity
      );
    } else {
      incompleteRow = rows.find(
        (row) => !row.resourceId || !row.engagementType || !row.amount
      );
    }

    if (incompleteRow) {
      toast.error("Please complete the current row before adding a new one.");
      return;
    }
    if (!isViewMode) {
      setRows([
        ...rows,
        Object.assign(
          { resourceId: "", engagementType: "", amount: "" },
          isTypeFIxed
            ? {
                quantity: "",
              }
            : {}
        ),
      ]);
    }
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = rows.map((row, i) => {
      if (i === index) {
        const updatedRow = { ...row, [field]: value };
        if (field === "resourceId") {
          updatedRow.engagementType = "";
          updatedRow.amount = ""; // Clears dependent fields when resource changes
        }
        return updatedRow;
      }
      return row;
    });
    setRows(updatedRows); // Updates row data
  };

  const handleDeleteRow = (index) => {
    if (!isViewMode) {
      setRows(rows.filter((_, i) => i !== index));
    }
  };

  const calculateConversionRate = (totalAmount = 0) => {
    const toNumber = Number(totalAmount);
    if (Number(conversionRate) && !isNaN(Number(conversionRate))) {
      return (toNumber / Number(conversionRate)).toFixed(2);
    }
    return toNumber;
  };

  const calculateTotal = () => {
    const totalAmount = rows.reduce(
      (sum, row) =>
        sum +
        (type.toLowerCase() === "fixed"
          ? parseFloat(Number(row.amount || 0) * Number(row.quantity || 0))
          : parseFloat(row.amount) || 0),
      0
    );
    // if (Number(conversionRate) && !isNaN(Number(conversionRate))) {
    //   const conversionRateIncluded = calculateConversionRate(totalAmount);
    //   setTotal(Number(conversionRateIncluded));
    //   return conversionRateIncluded;
    // }
    setTotal(totalAmount?.toFixed(2));
    return totalAmount?.toFixed(2);
  };

  const handleSave = () => {
    if (!rows.length) {
      toast.error("Please add atleast one resource");
      return;
    }
    // const conversionRateCalculatedAmountRows = rows.map((row) => ({
    //   ...row,
    //   amount: calculateConversionRate(row.amount) ?? 0,
    // }));
    onSave({
      type,
      currencyId: currency,
      resources: rows,
      total,
      conversionRate,
    });
  };

  const isResourceAlreadySelected = (resourceId) => {
    return rows.some((row) => row.resourceId === resourceId); // Prevents duplicate resource selection
  };

  async function getCostCalculationDetails() {
    try {
      const result = await getCostCalcutedData(actionId);
      if (result?.data?.status) {
        const { data } = result.data;
        const { type, currencyId, resources, total, conversionRate, _id } =
          data;
        setType(type);
        setCurrency(currencyId);
        setConversionRate(conversionRate);
        setRows(resources);
        resources?.forEach((res) => {
          setResourceCalculatedFullAmount((prev) => ({
            ...prev,
            [res.resourceId]: res.amount,
          }));
        });
        setTotal(total);
        setCostCalculationId(_id);
      }
    } catch (error) {
      console.error("Error fetching cost calculation details:", error);
    }
  }

  function provideUniqueComponentByType(type = "t & m", row = {}, index = 0) {
    if (type === "fixed") {
      return [
        <TableCell>Quantity</TableCell>,
        <TableCell>
          <TextField
            value={row.quantity ?? ""}
            onChange={(e) => handleRowChange(index, "quantity", e.target.value)}
            disabled={isViewMode}
            fullWidth
          />
        </TableCell>,
      ];
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (isViewMode) {
      getCostCalculationDetails();
    }
  }, [isViewMode]);

  useEffect(() => {
    calculateTotal();
  }, [
    conversionRate,
    rows.map(({ amount, quantity = 0 }) => `${amount}-${quantity}`).toString(),
  ]);
  // }, [conversionRate]);

  useEffect(() => {
    if (mode === "Create" || mode === "Edit") {
      const conversionRateCalculatedAmountRows = rows.map((row) => ({
        ...row,
        amount:
          calculateConversionRate(
            resourceCalculatedFullAmount[row.resourceId ?? ""]
          ) ?? 0,
      }));

      setRows(conversionRateCalculatedAmountRows);
    }
  }, [conversionRate]);

  console.log("addonOriginal", resourceCalculatedFullAmount, rows);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Cost Calculator</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center" marginBottom={2}>
          <Grid item xs={4}>
            <Typography>Type</Typography>
            <Select
              value={type}
              onChange={(e) => {
                setRows([]);
                setResourceCalculatedFullAmount({});
                setType(e.target.value);
              }}
              disabled={isViewMode}
              fullWidth
            >
              <MenuItem value="T & M">T & M</MenuItem>
              <MenuItem value="Fixed">Fixed Price</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Typography>Currency</Typography>
            <Select
              value={currency}
              onChange={handleCurrencyChange}
              disabled={isViewMode || isEdit}
              fullWidth
            >
              {currencies?.map((currency, index) => {
                return (
                  <MenuItem
                    key={index}
                    // value={`${currency?.code} (${currency?.symbol})`}
                    value={currency?._id}
                  >
                    {`${currency?.code} (${currency?.symbol})`}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Typography>Conversion Rate</Typography>
            <TextField
              value={conversionRate}
              onChange={handleConversionRateChange}
              disabled={isViewMode || isEdit}
              fullWidth
            />
          </Grid>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Resource Name</TableCell>
                <TableCell>Engagement Type</TableCell>
                <TableCell>Amount</TableCell>
                {provideUniqueComponentByType(type.toLocaleLowerCase())[0]}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Select
                      value={row.resourceId}
                      onChange={(e) => {
                        if (!isResourceAlreadySelected(e.target.value)) {
                          handleRowChange(index, "resourceId", e.target.value);
                        }
                      }}
                      disabled={isViewMode}
                      fullWidth
                      sx={{ minWidth: "160px" }}
                    >
                      {resources.map((resource) => (
                        <MenuItem
                          key={resource._id}
                          value={resource._id}
                          disabled={isResourceAlreadySelected(resource._id)}
                        >
                          {resource.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={row.engagementType}
                      onChange={(e) => {
                        const resourceDetails = resources.find(
                          ({ _id }) => _id === row.resourceId
                        );
                        if (resourceDetails) {
                          const baseAmount =
                            resourceDetails[`basePrice${e.target.value}`];
                          const addonWithBasePrice = calculateCost(
                            calculatorSettings,
                            Number(baseAmount)
                          );
                          console.log(
                            "addonWithBasePrice: " + addonWithBasePrice
                          );
                          if (
                            addonWithBasePrice &&
                            !isNaN(Number(addonWithBasePrice))
                          ) {
                            setResourceCalculatedFullAmount((prev) => ({
                              ...prev,
                              [row.resourceId]: addonWithBasePrice,
                            }));
                            setRows((prevRows) => {
                              const temp = [...prevRows];
                              temp[index].amount =
                                calculateConversionRate(addonWithBasePrice);
                              temp[index].engagementType = e.target.value;
                              return temp;
                            });

                            setTimeout(() => {
                              calculateTotal();
                            }, 1000);
                          }
                        }
                      }}
                      disabled={isViewMode}
                      fullWidth
                    >
                      <MenuItem value="Month">Monthly</MenuItem>
                      <MenuItem value="Week">Weekly</MenuItem>
                      <MenuItem value="Day">Daily</MenuItem>
                      <MenuItem value="Hour">Hourly</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.amount ?? ""}
                      onChange={(e) =>
                        handleRowChange(index, "amount", e.target.value)
                      }
                      disabled={isViewMode || type.toLowerCase() === "fixed"}
                      fullWidth
                    />
                  </TableCell>
                  {
                    provideUniqueComponentByType(
                      type.toLowerCase(),
                      row,
                      index
                    )[1]
                  }
                  <TableCell>
                    {!isViewMode && (
                      <IconButton onClick={() => handleDeleteRow(index)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {!isViewMode && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Button onClick={handleAddRow} color="primary">
                      Add Row
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={2} align="right">
                  <strong>Total:</strong>
                </TableCell>
                <TableCell colSpan={2}>{total}</TableCell>
              </TableRow>
              {currency === "INR" && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography color="textSecondary">
                      GST is extra (Only for INR)
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        {!isViewMode && !isEdit && (
          <Button
            disabled={
              type.toLowerCase() !== "fixed"
                ? !!rows.filter(({ resourceId, engagementType, amount }) => {
                    return !resourceId || !engagementType || !amount;
                  }).length
                : !!rows.filter(
                    ({ resourceId, engagementType, amount, quantity }) => {
                      return (
                        !resourceId || !engagementType || !amount || !quantity
                      );
                    }
                  ).length
            }
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        )}
        {isViewMode && !isDone && !isEdit && (
          <Button
            variant="contained"
            onClick={() => {
              setIsEdit(true);
              setCostCalculationDialogMode("Edit");
            }}
          >
            Edit
          </Button>
        )}
        {!isViewMode && isEdit && (
          <>
            <Button
              variant="contained"
              disabled={
                type.toLowerCase() !== "fixed"
                  ? !!rows.filter(({ resourceId, engagementType, amount }) => {
                      return !resourceId || !engagementType || !amount;
                    }).length
                  : !!rows.filter(
                      ({ resourceId, engagementType, amount, quantity }) => {
                        return (
                          !resourceId || !engagementType || !amount || !quantity
                        );
                      }
                    ).length
              }
              onClick={async () => {
                if (!rows.length) {
                  toast.error("Please add atleast one resource");
                  return;
                }
                // const conversionRateCalculatedAmountRows = rows.map((row) => ({
                //   ...row,
                //   amount: calculateConversionRate(row.amount) ?? 0,
                // }));
                await onUpdate(costCalculationId, {
                  type,
                  currencyId: currency,
                  resources: rows,
                  total,
                  conversionRate,
                });
                setIsEdit(false);
              }}
            >
              Update
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsEdit(false);
                setCostCalculationDialogMode("View");
              }}
              color="error"
            >
              Cancel
            </Button>
          </>
        )}
        <Button
          onClick={() => {
            setRows(initialRows);
            setType(initialType);
            // setCurrency(initialCurrency);
            setCurrency("");
            setConversionRate(initialConversionRate);
            onClose();
          }}
          color="secondary"
          variant="outlined"
        >
          Close {/* Close button */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CostCalculatorDialog;
