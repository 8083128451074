const { createContext, useState } = require("react");
const {
  getAllCalculatorSettings,
} = require("../services/calculatorSetting.service");

const CalculatorContext = createContext();

function CalculatorProvider({ children }) {
  const [calculatorSettings, setCalculatorSettings] = useState([]);

  async function getCalculatorSetting(costType = "T & M") {
    try {
      const res = await getAllCalculatorSettings();
      if (res?.data?.status) {
        const settings = res.data?.data?.find(({ type }) => type === costType);
        setCalculatorSettings(settings?.settings ?? []);
      }
    } catch (err) {
      console.error("Error fetching calculator settings:", err);
    }
  }

  return (
    <CalculatorContext.Provider
      value={{ getCalculatorSetting, calculatorSettings }}
    >
      {children}
    </CalculatorContext.Provider>
  );
}

export { CalculatorContext, CalculatorProvider };
