import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Avatar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { getCurrencies, updateCurrency } from "../../services/common.service";
function ConversionRate() {
  const [isLoaderEnable, setIsLoaderEnable] = useState(false);

  const [conversions, setConversions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    _id: null,
    currency: "",
    symbol: "",
    code: "",
    countryName: "",
    flag: "",
    conversionRate: "",
  });
  const [isViewMode, setIsViewMode] = useState(false);
  useEffect(() => {
    fetchConversions();
  }, []);

  const fetchConversions = async () => {
    try {
      setIsLoaderEnable(true);
      const response = await getCurrencies();
      if (response.data?.code === 200 && response.data?.status) {
        const data = response.data?.data;
        setIsLoaderEnable(false);

        setConversions(data);

        console.log("Fetched conversions data:", data);
        if (data.length === 0) {
          toast("Conversions list is currently empty");
        }
      }
    } catch (error) {
      setIsLoaderEnable(false);

      console.error("Error fetching conversions:", error);
      toast("Failed to fetch conversions. Please try again.");
    }
  };

  const handleOpenDialog = (data = null, viewMode = false) => {
    setDialogData(
      data
        ? {
            ...data,
          }
        : {
            _id: null,
            currency: "",
            symbol: "",
            code: "",
            countryName: "",
            flag: "",
            conversionRate: "",
          }
    );
    setIsViewMode(viewMode);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogData({
      _id: null,
      currency: "",
      symbol: "",
      code: "",
      countryName: "",
      flag: "",
      conversionRate: "",
    });
    setIsViewMode(false);
  };

  const handleSave = async () => {
    try {
      // Validate that conversionRate is a valid number and not 0
      const conversionRate = parseFloat(dialogData.conversionRate);
      if (isNaN(conversionRate) || conversionRate <= 0) {
        toast.error("Conversion Rate must be a valid number greater than 0.");
        return;
      }
      if (dialogData._id) {
        // Update Logic
        const response = await updateCurrency(dialogData._id, {
          conversionRate: dialogData.conversionRate,
        });

        if (response?.data.code === 200 && response?.data.status) {
          handleCloseDialog();
          await fetchConversions();
          toast.success("Currency updated successfully!");
        }
      } else {
        // await createConversion(dialogData);
        toast.success("Conversion added successfully!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
    }
    console.log("dialogData", dialogData);
  };

  // Delete Conversion
  const handleDelete = (_id) => {
    const updated = conversions.filter((item) => item._id !== _id);
    setConversions(updated);
    toast.success("Conversion deleted successfully!");
  };

  return (
    <>
      <Grid container spacing={2} p={3}>
        {/* Loading indicator */}
        {isLoaderEnable ? (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          // Conversion Cards
          conversions.map((conversion) => (
            <Grid item xs={12} sm={6} md={4} key={conversion._id}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardContent>
                  <Grid container spacing={1}>
                    {/* Action Buttons */}
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Avatar
                          alt={conversion?.countryName}
                          src={conversion?.flag}
                        />
                        <Stack direction="row">
                          {/* <IconButton
                            color="error"
                            onClick={() => handleDelete(conversion._id)} // delete
                          >
                            <DeleteIcon />
                          </IconButton> */}
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenDialog(conversion)} // edit
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenDialog(conversion, true)} // view
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </Grid>

                    {/* Country Name */}
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <Typography color="#2065d1">Country Name:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography fontWeight="650">
                            {conversion.countryName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Currency Name */}
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <Typography color="#2065d1">
                            Currency Name:
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography fontWeight="650">
                            {conversion.currency}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Currency Code */}
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <Typography color="#2065d1">
                            Currency Code:
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography fontWeight="650">
                            {`${conversion?.code} (${conversion?.symbol})`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Current Rate */}
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <Typography color="#2065d1">
                            Conversion Rate:
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography fontWeight="650">
                            {conversion.conversionRate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* Dialog for Create/Update */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {isViewMode
            ? "Conversion Details"
            : dialogData._id
            ? "Update Conversion"
            : "Create Conversion"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Currency */}
            <Grid item xs={4}>
              <TextField
                label="Currency"
                fullWidth
                value={dialogData.currency}
                onChange={(e) =>
                  setDialogData({ ...dialogData, currency: e.target.value })
                }
                disabled={isViewMode || dialogData._id !== null}
              />
            </Grid>
            {/* Symbol */}
            <Grid item xs={4}>
              <TextField
                label="Symbol"
                fullWidth
                value={dialogData.symbol}
                onChange={(e) =>
                  setDialogData({ ...dialogData, symbol: e.target.value })
                }
                disabled={isViewMode || dialogData._id !== null}
              />
            </Grid>
            {/* Code */}
            <Grid item xs={4}>
              <TextField
                label="Code"
                fullWidth
                value={dialogData.code}
                onChange={(e) =>
                  setDialogData({ ...dialogData, code: e.target.value })
                }
                disabled={isViewMode || dialogData._id !== null}
              />
            </Grid>
            {/* Country Name */}
            <Grid item xs={6}>
              <TextField
                label="Country Name"
                fullWidth
                value={dialogData.countryName}
                onChange={(e) =>
                  setDialogData({ ...dialogData, countryName: e.target.value })
                }
                disabled={isViewMode || dialogData._id !== null}
              />
            </Grid>
            {/* Conversion Rate */}
            <Grid item xs={6}>
              <TextField
                label="Conversion Rate"
                fullWidth
                type="number"
                value={dialogData.conversionRate}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    conversionRate: e.target.value,
                  })
                }
                disabled={isViewMode}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            {isViewMode ? "Close" : "Cancel"}
          </Button>
          {!isViewMode && (
            <Button onClick={handleSave} variant="contained" color="primary">
              {dialogData._id ? "Update" : "Create"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConversionRate;
