import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Paper,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import {
  getResources,
  createResource,
  updateResource,
  deleteResource,
  searchResource,
} from "../../services/resources.service";

function ResourceTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [search, setSearch] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    name: "",
    basePriceMonth: "",
    basePriceWeek: "",
    basePriceDay: "",
    basePriceHour: "",
    status: true,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalResources, setTotalResources] = useState(0);
  const [viewOnly, setViewOnly] = useState(false);

  useEffect(() => {
    fetchResources();
  }, [search, page, rowsPerPage]);
  // useEffect(() => {
  //   // Initialize with dummy data
  //   const dummyData = [
  //     {
  //       _id: 1,
  //       name: "Abhisek",
  //       basePriceMonth: "1000",
  //       basePriceWeek: "300",
  //       basePriceDay: "50",
  //       basePriceHour: "10",
  //       status: true,
  //     },
  //     {
  //       _id: 2,
  //       name: "Reema",
  //       basePriceMonth: "2000",
  //       basePriceWeek: "500",
  //       basePriceDay: "80",
  //       basePriceHour: "20",
  //       status: false,
  //     },
  //     {
  //       _id: 3,
  //       name: "Vivek",
  //       basePriceMonth: "1500",
  //       basePriceWeek: "400",
  //       basePriceDay: "60",
  //       basePriceHour: "15",
  //       status: true,
  //     },
  //   ];
  //   setResources(dummyData);
  // }, []);
  console.log("Search", search);

  const fetchResources = async () => {
    try {
      setIsLoading(true);
      let response;

      if (search) {
        // Call searchResource if a search query exists
        response = await searchResource(search);
      } else {
        // Default fetch all resources
        response = await getResources();
      }
      if (response?.data?.code === 200 && response?.data?.status) {
        const filteredResources = response?.data?.data?.filter((resource) =>
          resource.name.toLowerCase().includes(search.toLowerCase())
        );
        const startIndex = page * rowsPerPage;
        const paginatedResources = filteredResources.slice(
          startIndex,
          startIndex + rowsPerPage
        );
        setTotalResources(filteredResources.length);
        setResources(paginatedResources);
      } else {
        toast.error("Failed to fetch resources.");
      }
    } catch (error) {
      console.error("Error fetching resources:", error);
      toast.error("Error fetching resources.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (data = null, isView = false) => {
    setDialogData(
      data
        ? {
            ...data,
            createdAt: undefined, // Remove createdAt
            updatedAt: undefined, // Remove updatedAt
          }
        : {
            name: "",
            basePriceMonth: "",
            basePriceWeek: "",
            basePriceDay: "",
            basePriceHour: "",
            status: true,
          }
    );
    setViewOnly(isView);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setViewOnly(false);
    setDialogData({
      name: "",
      basePriceMonth: "",
      basePriceWeek: "",
      basePriceDay: "",
      basePriceHour: "",
      status: true,
    });
  };

  const handleSave = async () => {
    // Validate name field
    if (!dialogData.name || dialogData.name.trim() === "") {
      toast.error("Name cannot be empty.");
      return;
    }

    // Group price fields
    const priceFields = [
      "basePriceMonth",
      "basePriceWeek",
      "basePriceDay",
      "basePriceHour",
    ];

    // Check if any price field is empty
    const emptyPriceFields = priceFields.filter(
      (field) =>
        !dialogData[field] || dialogData[field].toString().trim() === ""
    );
    if (emptyPriceFields.length > 0) {
      toast.error(
        "All price fields must be filled out."
      );
      return;
    }
    const { basePriceMonth, basePriceWeek, basePriceDay, basePriceHour } =
      dialogData;

    // Validate prices
    if (
      parseFloat(basePriceMonth) <= 0 ||
      parseFloat(basePriceWeek) <= 0 ||
      parseFloat(basePriceDay) <= 0 ||
      parseFloat(basePriceHour) <= 0
    ) {
      toast.error("All prices must be greater than 0.");
      return;
    }

    try {
      const { _id, ...filteredData } = dialogData;
      if (_id) {
        // Update existing resource
        const response = await updateResource(_id, filteredData);
        if (response?.data?.code === 200 && response?.data?.status) {
          toast.success("Resource updated successfully!");
        } else {
          toast.error("Failed to update resource.");
        }
      } else {
        // Create new resource
        const response = await createResource(filteredData);
        if (response?.data?.code === 200 && response?.data?.status) {
          toast.success("Resource added successfully!");
        } else {
          toast.error("Failed to add resource.");
        }
      }
      handleCloseDialog();
      fetchResources();
    } catch (error) {
      console.error("Error saving resource:", error);
      toast.error("Error saving resource.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteResource(id);
      if (response?.data?.code === 200 && response?.data?.status) {
        toast.success("Resource deleted successfully!");
        fetchResources();
      } else {
        toast.error("Failed to delete resource.");
      }
    } catch (error) {
      console.error("Error deleting resource:", error);
      toast.error("Error deleting resource.");
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box p={3}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <TextField
          placeholder="Search resources"
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog()}
        >
          Add Resource
        </Button>
      </Stack>
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Base Price (Month)</TableCell>
                <TableCell>Base Price (Week)</TableCell>
                <TableCell>Base Price (Day)</TableCell>
                <TableCell>Base Price (Hour)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.length > 0 ? (
                resources.map((resource) => (
                  <TableRow key={resource._id}>
                    <TableCell>{resource.name}</TableCell>
                    <TableCell>{resource.basePriceMonth}</TableCell>
                    <TableCell>{resource.basePriceWeek}</TableCell>
                    <TableCell>{resource.basePriceDay}</TableCell>
                    <TableCell>{resource.basePriceHour}</TableCell>
                    <TableCell
                      sx={{
                        color: resource?.status ? "green" : "crimson",
                        fontWeight: "bold",
                      }}
                    >
                      {resource.status ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenDialog(resource, true)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenDialog(resource)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(resource._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Paper
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 3,
                        width: "100%",
                      }}
                    >
                      <Typography
                        gutterBottom
                        fontWeight="700"
                        color="#857C7A"
                        variant="subtitle1"
                      >
                        No records of resources.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalResources}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {viewOnly
            ? "View Resource"
            : dialogData._id
            ? "Update Resource"
            : "Add Resource"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name of the Resource"
                fullWidth
                value={dialogData.name}
                onChange={(e) =>
                  setDialogData({ ...dialogData, name: e.target.value })
                }
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Month"
                fullWidth
                type="number"
                value={dialogData.basePriceMonth}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    basePriceMonth: e.target.value,
                  })
                }
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Week"
                fullWidth
                type="number"
                value={dialogData.basePriceWeek}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    basePriceWeek: e.target.value,
                  })
                }
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Day"
                fullWidth
                type="number"
                value={dialogData.basePriceDay}
                onChange={(e) =>
                  setDialogData({ ...dialogData, basePriceDay: e.target.value })
                }
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Base Price per Hour"
                fullWidth
                type="number"
                value={dialogData.basePriceHour}
                onChange={(e) =>
                  setDialogData({
                    ...dialogData,
                    basePriceHour: e.target.value,
                  })
                }
                disabled={viewOnly}
              />
            </Grid>
            {/* Status */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  name="status"
                  value={dialogData.status ? "active" : "inactive"}
                  onChange={(e) =>
                    setDialogData({
                      ...dialogData,
                      status: e.target.value === "active",
                    })
                  }
                  disabled={viewOnly}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          {!viewOnly && (
            <Button onClick={handleSave} variant="contained" color="primary">
              {dialogData._id ? "Update" : "Add"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ResourceTab;
