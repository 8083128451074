import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import {
  getAllCalculatorSettings,
  updateCalculatorSetting,
} from "../../services/calculatorSetting.service";
function CalculatorSetting() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [rows, setRows] = useState([]);
  const [calculatorTypes, setCalculatorTypes] = useState([]); // Stores backend response
  const [currentCalculatorType, setCurrentCalculatorType] = useState("");
  // Fetch all calculator settings
  useEffect(() => {
    fetchAllCalculatorSettings();
    console.log("calculatorTypes", calculatorTypes);
  }, []);

  // Fetch all calculator settings
  // const fetchAllCalculatorSettings = async () => {
  //   const response = [
  //     {
  //       _id: "1",
  //       type: "T & M",
  //       settings: [
  //         {
  //           serialNumber: 1,
  //           head: "Parameter",
  //           source: "Parameter",
  //           value: "NO INPUT NEEDED",
  //         },
  //         { serialNumber: 2, head: "B", source: "Input", value: "2" },
  //         { serialNumber: 3, head: "C", source: "Input", value: "2%" },
  //         { serialNumber: 4, head: "D", source: "Calculate", value: "(1*2)*3" },
  //       ],
  //     },
  //     {
  //       _id: "2",
  //       type: "Fixed",
  //       settings: [],
  //     },
  //   ];
  //   setCalculatorTypes(response);
  // };

  const fetchAllCalculatorSettings = async () => {
    try {
      const response = await getAllCalculatorSettings();
      setCalculatorTypes(response.data?.data);
    } catch (error) {
      console.error("Error fetching calculator settings:", error);
    }
  };

  const handleOpenDialog = (type, action) => {
    const selectedType = calculatorTypes.find((item) => item.type === type);
    const defaultRow = {
      serialNumber: 1,
      head: "Parameter",
      source: "Parameter",
      value: "NO INPUT NEEDED",
    };
    const initialRows =
      selectedType && selectedType.settings.length > 0
        ? [
            defaultRow,
            ...selectedType.settings.filter((row) => row.serialNumber !== 1),
          ]
        : [defaultRow];
    setDialogType(action); // "View" or "Edit"
    setCurrentCalculatorType(type);
    setRows(initialRows);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType("");
    setCurrentCalculatorType("");
    setRows([]);
  };

  const handleAddRow = () => {
    const newRow = {
      serialNumber: rows.length + 1,
      head: "",
      source: "",
      value: "",
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    const reindexedRows = updatedRows.map((row, i) => ({
      ...row,
      serialNumber: i + 1,
    }));
    setRows(reindexedRows);
  };

  const handleRowChange = (index, field, value) => {
    if (field === "value") {
      // Allow only numbers, mathematical operators, and whitespace
      const validPattern = /^[0-9+\-*/().\s%]*$/;

      if (!validPattern.test(value)) {
        toast.warning("Only numbers and mathematical expressions are allowed.");
        return;
      }
    }
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const handleSaveSettings = async () => {
    try {
      const settingsToSave = rows.map(({ _id, ...rest }) => ({
        ...rest,
      }));
      console.log("settingsToSave", settingsToSave);
      console.log("dialogType", dialogType);
      // Ensure we find the correct selected type
      const selectedType = calculatorTypes.find(
        (item) => item.type === currentCalculatorType
      );

      // Validate selectedType and settingsToSave
      if (!selectedType) {
        toast.error("Unable to find the selected calculator type.");
        return;
      }

      if (!settingsToSave || settingsToSave.length === 0) {
        toast.warning("No settings to save.");
        return;
      }
      // Call the update API
      const response = await updateCalculatorSetting(selectedType._id, {
        settings: settingsToSave,
      });
      // Extract and show success message from response
      if (response?.data && response.data?.message) {
        toast.success(response.data?.message);
      }

      // Refresh the settings and close the dialog
      await fetchAllCalculatorSettings();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving calculator settings:", error);
    }
  };

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        {calculatorTypes.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">{item.type}</Typography>
                  <Stack direction="row" spacing={1}>
                    {item.settings.length > 0 ? (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenDialog(item.type, "View")}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenDialog(item.type, "Edit")}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenDialog(item.type, "Edit")}
                      >
                        Add
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {dialogType ? `Manage ${dialogType} Calculator Setting` : "Manage"}
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SL No</TableCell>
                  <TableCell>Head</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Value</TableCell>
                  {dialogType === "Edit" && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.serialNumber}</TableCell>
                    <TableCell>
                      <TextField
                        value={row.head}
                        onChange={(e) =>
                          handleRowChange(index, "head", e.target.value)
                        }
                        fullWidth
                        disabled={
                          dialogType === "View" || row.serialNumber === 1
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        value={row.source}
                        onChange={(e) =>
                          handleRowChange(index, "source", e.target.value)
                        }
                        fullWidth
                        disabled={
                          dialogType === "View" || row.serialNumber === 1
                        }
                      >
                        {row.serialNumber === 1 && (
                          <MenuItem value="Parameter">Parameter</MenuItem>
                        )}
                        {row.serialNumber !== 1 && (
                          <MenuItem value="Input">Input</MenuItem>
                        )}
                        {row.serialNumber !== 1 && (
                          <MenuItem value="Calculate">Calculate</MenuItem>
                        )}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={row.value}
                        onChange={(e) =>
                          handleRowChange(index, "value", e.target.value)
                        }
                        fullWidth
                        disabled={
                          dialogType === "View" || row.serialNumber === 1
                        }
                      />
                    </TableCell>
                    {dialogType === "Edit" && row.serialNumber !== 1 && (
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteRow(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {dialogType === "Edit" && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddRow}
              sx={{ mt: 2 }}
            >
              Add Rows
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          {dialogType === "View" ? (
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleCloseDialog} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleSaveSettings}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CalculatorSetting;
